var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "document-preview" }, [
    _c("div", { staticClass: "document-preview-text-container" }, [
      _c("div", { staticClass: "document-preview-text-wrapper" }, [
        _c(
          "div",
          { staticClass: "document-preview-text" },
          [
            !_vm.loading
              ? _c("HTMLRenderer", { attrs: { value: _vm.text } })
              : _c(
                  "div",
                  { staticClass: "document-preview-text-loading" },
                  [
                    _c("b-skeleton", {
                      attrs: { height: "1.5rem", width: "90%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { height: "1.5rem", width: "40%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { height: "1.5rem", width: "70%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { height: "1.5rem", width: "60%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { height: "1.5rem", width: "50%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { height: "1.5rem", width: "20%" }
                    })
                  ],
                  1
                )
          ],
          1
        ),
        _c("div", { staticClass: "document-preview-text-padding" })
      ])
    ]),
    _vm.output.tool && _vm.output.tool.dynamic
      ? _c(
          "div",
          { staticClass: "document-preview-prompt" },
          [
            _c("TextInput", {
              staticClass: "document-preview-prompt-input",
              attrs: {
                placeholder:
                  'Optional instruction, try: "Make this sound easier" or "Put more emphasis on X"',
                "max-length": 200,
                disabled: _vm.loading
              },
              on: {
                submit: function() {
                  return _vm.generateOutput(true)
                }
              },
              model: {
                value: _vm.prompt,
                callback: function($$v) {
                  _vm.prompt = $$v
                },
                expression: "prompt"
              }
            }),
            _c("Button", {
              attrs: {
                text: "Regenerate",
                icon: "ai",
                size: "xs",
                loading: _vm.loading
              },
              on: {
                click: function() {
                  return _vm.generateOutput(true)
                }
              }
            }),
            _vm.outputPrompt
              ? _c("Button", {
                  attrs: {
                    text: "Reset",
                    size: "xs",
                    type: "white",
                    loading: _vm.loading
                  },
                  on: { click: _vm.resetOutput }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }