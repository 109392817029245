<template>
  <b-modal active @close="close">
    <div class="meeting-modal">
      <div class="meeting-modal-info">
        <p class="meeting-modal-info-title">{{ title }}</p>
        <Button
          :text="linkButtonText"
          icon="internal"
          type="light"
          @click="gotoItem"
        />
      </div>
      <p v-if="updated" class="meeting-modal-updated">
        Last updated {{ updated }}
      </p>
      <div v-if="attributes.length" class="meeting-modal-attributes">
        <Tag
          v-for="a in attributes"
          :key="a"
          :text="a"
          type="light"
          size="xs"
        />
      </div>
      <div v-if="image || summary" class="meeting-modal-content">
        <img v-if="image" :src="image" class="meeting-modal-image" />
        <p v-if="summary" class="meeting-modal-summary">{{ summary }}</p>
      </div>
      <div class="meeting-modal-explanation">
        <p class="meeting-modal-explanation-header">
          <img
            src="@/assets/icons/ai.svg"
            alt=""
            class="meeting-modal-explanation-header-icon"
            :class="{ loading }"
          />
          Explanation
        </p>
        <LoadingDots v-if="loading" />
        <MarkdownEdit
          v-else-if="explanation"
          :value="explanation"
          class="meeting-modal-explanation-content"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { formatDate } from '@/util'
import LoadingDots from '@c/library/LoadingDots.vue'
import Button from '@c/library/Button.vue'
import Tag from '@c/library/Tag.vue'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'
import {
  getMeetingInspirationInfo,
  getMeetingOfferingInfo,
  getMeetingReferenceInfo
} from '@/services/meetingService'

export default {
  name: 'MeetingORIModal',
  components: { LoadingDots, Button, Tag, MarkdownEdit },
  props: {
    item: {
      type: Object,
      required: true
    },
    meeting: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    explanation: '',
    selectLoading: false
  }),
  computed: {
    title() {
      return this.item?.title || this.item?.name || ''
    },
    updated() {
      const date = this.item?.date_updated || this.item?.date_created || ''
      return date ? formatDate(date) : ''
    },
    attributes() {
      return (this.item?.attributes || []).map((a) => a.name)
    },
    image() {
      const org = this.item?.account?.organisation
      return this.item?.image || org?.logo || ''
    },
    summary() {
      return this.item?.summary || ''
    },
    linkButtonText() {
      const typeName = {
        inspirations: 'inspiration',
        offerings: 'offering',
        references: 'reference'
      }[this.type]
      return `Open ${typeName} page`
    }
  },
  created() {
    this.loadExplanation()
  },
  methods: {
    async loadExplanation() {
      if (this.loading) return
      try {
        this.loading = true
        const explanationFunction = {
          inspirations: getMeetingInspirationInfo,
          offerings: getMeetingOfferingInfo,
          references: getMeetingReferenceInfo
        }[this.type]
        const { explanation } = await explanationFunction({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          ori_id: this.item.uuid
        })
        this.explanation = explanation
      } catch (e) {
        this.$console.debug('Error while generating an explanation', e)
        this.$toast.error(e, 'generating explanation')
      } finally {
        this.loading = false
      }
    },
    gotoItem() {
      const href = this.$router.resolve({
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          ori_type: this.type
        }
      }).href
      window.open(href, '_blank')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-modal {
  width: min(50rem, 90vw);
  max-height: 90vh;
  overflow-y: auto;
  padding: 1.75rem;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-size: 1.75rem;
      font-weight: 700;
    }
  }

  &-updated {
    color: #60666b;
  }

  &-attributes {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
  }

  &-image {
    width: 8.5rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    object-fit: cover;
    float: left;
    border-radius: 6px;
  }

  &-summary {
    color: #60666b;
    white-space: pre-wrap;
  }

  &-explanation {
    background: #f9f9fa;
    border-radius: 6px;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    padding: 0.85rem;

    &-header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;

      &-icon {
        height: 1.2rem;
      }
    }
  }

  &-buttons {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
