var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "translate-output" }, [
      _c(
        "div",
        { staticClass: "translate-output-header" },
        [
          _c("p", { staticClass: "translate-output-header-title" }, [
            _vm._v(' Translate "' + _vm._s(_vm.output.name) + '" ')
          ]),
          _c("Button", {
            attrs: { icon: "close", type: "grey" },
            on: { click: _vm.close }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "translate-output-content" },
        [
          _c("p", { staticClass: "translate-output-content-text" }, [
            _vm._v(" What language would you like to translate this asset to? ")
          ]),
          _c("LanguageSelect", {
            model: {
              value: _vm.selectedLanguage,
              callback: function($$v) {
                _vm.selectedLanguage = $$v
              },
              expression: "selectedLanguage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "translate-output-footer" },
        [
          _c("Button", {
            attrs: { text: "Cancel", type: "white" },
            on: { click: _vm.close }
          }),
          _c("Button", {
            attrs: { text: "Translate" },
            on: { click: _vm.translate }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }