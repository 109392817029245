var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Tabs", {
    attrs: {
      tabs: _vm.tabs,
      selected: function(t) {
        return t.key === _vm.tab
      },
      "max-height": _vm.maxHeight,
      "full-width": true
    },
    on: { select: _vm.selectTab },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function(ref) {
          var t = ref.tab
          return [
            _c(
              "div",
              { staticClass: "meeting-suggestions-trigger" },
              [
                _vm._v(" " + _vm._s(t.label) + " "),
                !_vm.suggestionsLoading.includes(t.key) &&
                (t.key === "offerings" || !_vm.regenRefInsp)
                  ? _c("Tag", {
                      attrs: {
                        text: "" + _vm.suggestionLengths[t.key],
                        size: "xxs",
                        type: "light-solid"
                      }
                    })
                  : _c("img", {
                      staticClass: "meeting-suggestions-trigger-loading",
                      attrs: {
                        src: require("@/assets/icons/spinner.svg"),
                        alt: ""
                      }
                    })
              ],
              1
            )
          ]
        }
      },
      {
        key: "tab",
        fn: function(ref) {
          var t = ref.tab
          return [
            _c("MeetingORI", {
              key:
                t.key === "offerings" ? t.key : t.key + "-" + _vm.meetingORIKey,
              staticClass: "meeting-suggestions-tab",
              attrs: {
                type: t.key,
                selected: _vm.selected[t.key],
                meeting: _vm.meeting,
                disabled: _vm.loading,
                "refresh-inputs": _vm.tab === t.key
              },
              on: {
                suggestions: function(s) {
                  return _vm.loadSuggestions(t.key, s)
                },
                input: function(i) {
                  return _vm.handleEditMeeting(t, i)
                },
                remove: function(i) {
                  return _vm.handleEditMeeting(t, i, true)
                },
                loading: function(val) {
                  return _vm.setLoading(t, val)
                },
                detail: function(item) {
                  return _vm.$emit("detail", item, t.key)
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }