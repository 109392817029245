<template>
  <b-modal active :can-cancel="['outside', 'escape']" @close="close">
    <div class="meeting-modal">
      <div class="meeting-modal-header">
        <div class="meeting-modal-header-info">
          <Avatar
            :user="{ avatar: organisation.icon || organisation.logo }"
            fallback-icon="company"
          />
          {{ organisation.name }}
        </div>
        <div class="meeting-modal-header-btns">
          <a
            v-if="organisation && organisation.linkedin_url"
            :href="organisation.linkedin_url"
          >
            <Button
              text="LinkedIn"
              icon="external-bold"
              type="light"
              size="xs"
            />
          </a>
          <Button icon="close" type="grey" size="xs" @click="close" />
        </div>
      </div>
      <div class="meeting-modal-content">
        <div class="meeting-modal-sidebar">
          <b-tooltip
            v-for="(insight, idx) in insights"
            :key="`meeting-account-insight-tab-${idx}`"
            :active="!insight.summary"
            label="Not available"
            :position="idx ? 'is-top' : 'is-bottom'"
            type="is-dark"
          >
            <div
              class="meeting-modal-sidebar-item"
              :class="{
                selected: selectedInsight.name === insight.name,
                disabled: !insight.summary
              }"
              @click="() => selectInsight(insight)"
            >
              {{ insight.name }}
              <img
                v-if="!insight.summary"
                src="@/assets/icons/close-circle-filled.svg"
                alt=""
                class="meeting-modal-sidebar-item-na"
              />
            </div>
          </b-tooltip>
        </div>
        <div class="meeting-modal-insight">
          <div v-if="showSelected" class="meeting-modal-insight-block">
            <div class="meeting-modal-insight-block-header">
              <img
                src="@/assets/icons/ai.svg"
                alt=""
                class="meeting-modal-insight-block-header-icon"
              />
              <p class="meeting-modal-insight-block-header-title">Summary</p>
            </div>
            <MarkdownEdit :key="summaryKey" :value="selectedInsight.summary" />
          </div>
          <div v-if="showSelected" class="meeting-modal-insight-block">
            <div class="meeting-modal-insight-block-header">
              <p class="meeting-modal-insight-block-header-title">Sources</p>
              <Tag
                :text="`${(selectedInsight.sources || []).length}`"
                type="light-solid"
                size="xxs"
              />
            </div>
            <div class="meeting-modal-insight-block-grid">
              <a
                v-for="(source, idx) in visibleSources"
                :key="`meeting-account-insight-source-${idx}`"
                :title="source"
                :href="source"
                target="_blank"
                class="meeting-modal-insight-block-grid-item"
              >
                {{ source }}
              </a>
            </div>
            <p
              v-if="
                showSelected &&
                visibleSources.length < selectedInsight.sources.length
              "
              class="meeting-modal-insight-block-showmore"
              @click="() => (sourcesShowMore = !sourcesShowMore)"
            >
              Show
              {{ selectedInsight.sources.length - visibleSources.length }} more
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import Tag from '@c/library/Tag.vue'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'

export default {
  name: 'MeetingAccountModal',
  components: { Button, Avatar, Tag, MarkdownEdit },
  props: {
    meeting: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    selectedInsight: {},
    sourcesShowMore: false,
    summaryKey: 0
  }),
  computed: {
    account() {
      return this.meeting.account
    },
    organisation() {
      return this.account?.organisation
    },
    insights() {
      return this.organisation?.insights || []
    },
    showSelected() {
      return Object.keys(this.selectedInsight || {}).length > 0
    },
    visibleSources() {
      return this.sourcesShowMore
        ? this.selectedInsight.sources
        : this.selectedInsight.sources?.slice(0, 4)
    }
  },
  created() {
    const first = (this.insights || []).find((i) => !!i.summary)
    if (first) this.selectedInsight = first
  },
  methods: {
    close() {
      this.$emit('close')
    },
    selectInsight(insight) {
      this.selectedInsight = insight
      this.sourcesShowMore = false
      this.summaryKey++
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-modal {
  background: white;
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.75rem;
      font-size: 1.25rem;
      font-weight: 700;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.75rem;
    }
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    height: calc(75vh - 4.5rem);
  }

  &-sidebar {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.25rem;
    padding: 1.5rem;
    border-right: 1px solid rgba(#000, 0.08);
    background: #f9f9fa;
    border-bottom-left-radius: 8px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    &-item {
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &:hover {
        background: rgba(#000, 0.04);
      }

      &.selected {
        color: $primary;
        background: #ebf1ff;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }

      &-na {
        height: 1.2rem;
        margin-left: auto;
        filter: brightness(0) saturate(100%) invert(37%) sepia(5%)
          saturate(584%) hue-rotate(165deg) brightness(102%) contrast(84%);
      }
    }
  }

  &-insight {
    flex: 3;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1.5rem;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    &-block {
      background: #f1f2f3;
      border-radius: 4px;
      padding: 0.75rem;
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;

      &-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;

        &-icon {
          height: 1rem;
        }

        &-title {
          font-size: 0.875rem;
          font-weight: 600;
        }
      }

      &-content {
        font-size: 0.875rem;
      }

      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;

        &-item {
          border-radius: 6px;
          border: 1px solid rgba(#000, 0.08);
          color: $primary;
          padding: 0.75rem;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: background 0.2s;

          &:hover {
            background: #ebf1ff;
            border: 1px solid $primary;
          }
        }
      }

      &-showmore {
        align-self: center;
        width: fit-content;
        font-size: 0.875rem;
        color: $primary;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
