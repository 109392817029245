var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "export-results" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "export-results-list" },
      _vm._l(_vm.outputs, function(output) {
        return _c(
          "div",
          {
            key: "export-results-" + output.uuid,
            staticClass: "export-results-list-item"
          },
          [
            _c("img", {
              staticClass: "export-results-list-item-icon",
              attrs: {
                src: require("@/assets/file_type/" +
                  (output.tool.type === "presentation"
                    ? "powerpoint"
                    : "word") +
                  ".svg"),
                alt: ""
              }
            }),
            _c("p", { staticClass: "export-results-list-item-name" }, [
              _vm._v(_vm._s(output.name))
            ]),
            _c("StatusOrb", {
              attrs: {
                type: _vm.finished.includes(output.uuid) ? "green" : "orange",
                text: _vm.finished.includes(output.uuid)
                  ? (_vm.isDownload ? "Download" : "Upload") + " complete"
                  : (_vm.isDownload ? "Download" : "Upload") + "ing...",
                "show-text": true
              }
            }),
            !_vm.isDownload
              ? _c(
                  "b-tooltip",
                  {
                    staticClass: "export-results-list-item-link",
                    class: { invisible: !_vm.finished.includes(output.uuid) },
                    attrs: {
                      label: "View on " + _vm.uploadProvider,
                      type: "is-dark",
                      position: "is-left",
                      "append-to-body": false
                    }
                  },
                  [
                    _c("Button", {
                      attrs: {
                        icon: "external-bold",
                        type: "light",
                        disabled: !_vm.finished.includes(output.uuid)
                      },
                      on: {
                        click: function() {
                          return _vm.gotoResult(output)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "export-results-updowns" },
      _vm._l(_vm.outputs, function(output) {
        return _c("UploadDownload", {
          key: "export-updown-" + output.uuid,
          ref: "export-updown-" + output.uuid,
          refInFor: true,
          attrs: {
            "props-call": _vm.propsCalls[output.uuid],
            "upload-callback": function(res) {
              return _vm.handleUploadFinish(output, res)
            },
            "download-callback": function() {
              return _vm.handleDownloadFinish(output)
            },
            "show-result": false
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "export-results-hero" }, [
      _c("img", {
        staticClass: "export-results-hero-img",
        attrs: { src: require("@/assets/ori_loading.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }