var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: "", "can-cancel": ["outside", "escape"] },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "meeting-modal" }, [
        _c("div", { staticClass: "meeting-modal-header" }, [
          _c(
            "div",
            { staticClass: "meeting-modal-header-info" },
            [
              _c("Avatar", {
                attrs: {
                  user: {
                    avatar: _vm.organisation.icon || _vm.organisation.logo
                  },
                  "fallback-icon": "company"
                }
              }),
              _vm._v(" " + _vm._s(_vm.organisation.name) + " ")
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "meeting-modal-header-btns" },
            [
              _vm.organisation && _vm.organisation.linkedin_url
                ? _c(
                    "a",
                    { attrs: { href: _vm.organisation.linkedin_url } },
                    [
                      _c("Button", {
                        attrs: {
                          text: "LinkedIn",
                          icon: "external-bold",
                          type: "light",
                          size: "xs"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("Button", {
                attrs: { icon: "close", type: "grey", size: "xs" },
                on: { click: _vm.close }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "meeting-modal-content" }, [
          _c(
            "div",
            { staticClass: "meeting-modal-sidebar" },
            _vm._l(_vm.insights, function(insight, idx) {
              return _c(
                "b-tooltip",
                {
                  key: "meeting-account-insight-tab-" + idx,
                  attrs: {
                    active: !insight.summary,
                    label: "Not available",
                    position: idx ? "is-top" : "is-bottom",
                    type: "is-dark"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "meeting-modal-sidebar-item",
                      class: {
                        selected: _vm.selectedInsight.name === insight.name,
                        disabled: !insight.summary
                      },
                      on: {
                        click: function() {
                          return _vm.selectInsight(insight)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(insight.name) + " "),
                      !insight.summary
                        ? _c("img", {
                            staticClass: "meeting-modal-sidebar-item-na",
                            attrs: {
                              src: require("@/assets/icons/close-circle-filled.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "meeting-modal-insight" }, [
            _vm.showSelected
              ? _c(
                  "div",
                  { staticClass: "meeting-modal-insight-block" },
                  [
                    _c(
                      "div",
                      { staticClass: "meeting-modal-insight-block-header" },
                      [
                        _c("img", {
                          staticClass:
                            "meeting-modal-insight-block-header-icon",
                          attrs: {
                            src: require("@/assets/icons/ai.svg"),
                            alt: ""
                          }
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "meeting-modal-insight-block-header-title"
                          },
                          [_vm._v("Summary")]
                        )
                      ]
                    ),
                    _c("MarkdownEdit", {
                      key: _vm.summaryKey,
                      attrs: { value: _vm.selectedInsight.summary }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSelected
              ? _c("div", { staticClass: "meeting-modal-insight-block" }, [
                  _c(
                    "div",
                    { staticClass: "meeting-modal-insight-block-header" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "meeting-modal-insight-block-header-title"
                        },
                        [_vm._v("Sources")]
                      ),
                      _c("Tag", {
                        attrs: {
                          text: "" + (_vm.selectedInsight.sources || []).length,
                          type: "light-solid",
                          size: "xxs"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "meeting-modal-insight-block-grid" },
                    _vm._l(_vm.visibleSources, function(source, idx) {
                      return _c(
                        "a",
                        {
                          key: "meeting-account-insight-source-" + idx,
                          staticClass: "meeting-modal-insight-block-grid-item",
                          attrs: {
                            title: source,
                            href: source,
                            target: "_blank"
                          }
                        },
                        [_vm._v(" " + _vm._s(source) + " ")]
                      )
                    }),
                    0
                  ),
                  _vm.showSelected &&
                  _vm.visibleSources.length < _vm.selectedInsight.sources.length
                    ? _c(
                        "p",
                        {
                          staticClass: "meeting-modal-insight-block-showmore",
                          on: {
                            click: function() {
                              return (_vm.sourcesShowMore = !_vm.sourcesShowMore)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " Show " +
                              _vm._s(
                                _vm.selectedInsight.sources.length -
                                  _vm.visibleSources.length
                              ) +
                              " more "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }