var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Dropdown", {
    attrs: { items: _vm.actions, disabled: _vm.disabled },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "output-actions",
                class: { loading: _vm.removeLoading }
              },
              [
                _c("Button", {
                  staticClass: "output-actions-trigger",
                  attrs: { icon: "kebab", type: "grey", size: "xs" }
                }),
                _c("div", { staticClass: "output-actions-loading-wrapper" }, [
                  _c("img", {
                    staticClass: "output-actions-loading",
                    attrs: {
                      src: require("@/assets/icons/spinner.svg"),
                      alt: ""
                    }
                  })
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }