<template>
  <div class="overview">
    <div
      v-for="(block, idx) in blocks"
      :key="`overview-block-${idx}`"
      class="overview-block"
      :class="{ disabled: loading }"
    >
      <div class="overview-block-header">
        <p class="overview-block-header-title">{{ block.name }}</p>
        <Button
          v-if="block.suggestions"
          :text="block.suggestions"
          size="xxs"
          type="light-solid"
          @click="() => handleEdit(block)"
        />
        <div class="overview-block-header-edit-wrapper">
          <b-tooltip
            v-if="block.key !== 'account'"
            :label="`Edit meeting ${block.name.toLowerCase()}`"
            type="is-dark"
            position="is-right"
            :append-to-body="true"
          >
            <Button
              icon="edit"
              size="xs"
              type="grey"
              class="overview-block-header-edit"
              @click="() => handleEdit(block)"
            />
          </b-tooltip>
        </div>
      </div>
      <ul v-if="block.items.length" class="overview-block-list">
        <li
          v-for="(item, i) in block.items"
          :key="`overview-block-item-${i}`"
          class="overview-block-list-item"
          @click="() => handleSelect(block, item)"
        >
          <p v-if="block.key === 'notes'" class="overview-block-list-item-dot">
            &bull;
          </p>
          <Avatar
            v-if="!block.hideImage"
            :user="{ avatar: item.image }"
            size="xs"
            :fallback-icon="block.fallbackIcon"
          />
          <div class="overview-block-list-item-text">
            <p
              class="overview-block-list-item-text-header"
              :class="{ bold: item.subtext }"
            >
              {{ item.text }}
            </p>
            <p v-if="item.subtext" class="overview-block-list-item-subtext">
              {{ item.subtext }}
            </p>
          </div>
          <div v-if="block.linkedin" class="overview-block-list-item-scrim">
            <img
              src="@/assets/logo/linkedin.svg"
              alt=""
              class="overview-block-list-item-scrim-logo"
            />
            <img
              src="@/assets/icons/arrow-right.svg"
              alt=""
              class="overview-block-list-item-scrim-arrow"
            />
          </div>
        </li>
      </ul>
      <div v-if="!block.items.length" class="overview-block-empty">
        <p
          v-if="!loading"
          class="overview-block-add"
          @click="() => handleEdit(block)"
        >
          Add
        </p>
        <p v-else class="overview-block-loading">Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'MeetingOverviewSidebar',
  components: { Button, Avatar },
  props: {
    meeting: {
      type: Object,
      default: () => undefined
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    blocks() {
      const org = this.meeting?.account?.organisation
      return [
        {
          name: 'Company',
          items: (org ? [org] : []).map((a) => ({
            text: a.name,
            image: a.icon || a.logo,
            url: org.linkedin_url,
            item: a
          })),
          key: 'account',
          fallbackIcon: 'company',
          linkedin: !org?.insights?.length
        },
        {
          name: 'Audience',
          items: (this.meeting?.people || []).map((person) => ({
            text: this.$umodel.full_name(person),
            subtext: person.role,
            image: person.avatar,
            url: person.linkedin_url,
            item: person
          })),
          key: 'people',
          fallbackIcon: 'user',
          linkedin: true
        },
        {
          name: 'Notes',
          items: (this.meeting?.notes || []).map((note) => ({
            text: note.title,
            item: note
          })),
          key: 'notes',
          hideImage: true
        },
        {
          name: 'Offerings',
          items: (this.meeting?.offerings || []).map((offering) => ({
            text: offering.name,
            image: offering.image,
            item: offering
          })),
          key: 'offerings',
          fallbackIcon: 'globe',
          suggestions: (() => {
            let values = this.meeting?.suggestions?.offerings || []
            values = values.filter(
              (suggestion) =>
                !this.meeting?.offerings?.find(
                  (offering) => offering.uuid === suggestion.uuid
                )
            )
            const count = values.length || 0
            return count ? `${count} suggestion${count === 1 ? '' : 's'}` : ''
          })()
        },
        {
          name: 'Cases',
          items: (this.meeting?.references || []).map((reference) => ({
            text: reference.name,
            image:
              reference.image ||
              reference.account?.organisation?.logo ||
              reference.account?.organisation?.icon ||
              '',
            item: reference
          })),
          key: 'references',
          fallbackIcon: 'globe',
          suggestions: (() => {
            let values = this.meeting?.suggestions?.references || []
            values = values.filter(
              (suggestion) =>
                !this.meeting?.references?.find(
                  (reference) => reference.uuid === suggestion.uuid
                )
            )
            const count = values.length || 0
            return count ? `${count} suggestion${count === 1 ? '' : 's'}` : ''
          })()
        },
        {
          name: 'Inspiration',
          items: (this.meeting?.inspirations || []).map((inspiration) => ({
            text: inspiration.name,
            image: inspiration.image,
            item: inspiration
          })),
          key: 'inspirations',
          fallbackIcon: 'globe',
          suggestions: (() => {
            let values = this.meeting?.suggestions?.inspirations || []
            values = values.filter(
              (suggestion) =>
                !this.meeting?.inspirations?.find(
                  (inspiration) => inspiration.uuid === suggestion.uuid
                )
            )
            const count = values.length || 0
            return count ? `${count} suggestion${count === 1 ? '' : 's'}` : ''
          })()
        }
      ]
    }
  },
  methods: {
    handleEdit(block) {
      this.$emit('edit', block)
    },
    handleSelect(block, item) {
      this.$emit('select', block, item)
    }
  }
}
</script>

<style lang="scss" scoped>
.overview {
  &-block {
    border-bottom: 1px solid rgba(#000, 0.08);
    border-right: 2px solid transparent;
    background: white;
    transition: border-right 0.2s ease, background 0.2s ease;
    padding: 1.15rem 1rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &:hover {
      border-right: 2px solid $primary;
      background: rgba($primary, 0.04);

      & .overview-block-header-edit {
        opacity: 1;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.75rem;

      &-title {
        font-weight: 600;
      }

      &-edit {
        opacity: 0;
        transition: opacity 0.2s ease;

        &-wrapper {
          margin-left: auto;
        }
      }
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.75rem;
        padding: 0.35rem 0.5rem;
        border-radius: 4px;
        cursor: pointer;
        color: #60666b;
        position: relative;

        &:hover {
          background: rgba(#000, 0.04);

          & .overview-block-list-item-scrim {
            opacity: 1;
          }
        }

        &-dot {
          color: #60666b;
        }

        &-text {
          display: flex;
          flex-flow: column nowrap;

          &-header {
            &.bold {
              font-weight: 600;
            }
          }
        }

        &-subtext {
          font-size: 0.85rem;
        }

        &-scrim {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          padding: 0.5rem;
          gap: 0.25rem;
          background: linear-gradient(to right, rgba(#fff, 0.2), rgba(#fff, 1));
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 4px;
          opacity: 0;
          transition: opacity 0.5s ease;

          &-logo,
          &-arrow {
            height: 1.5rem;
          }
        }
      }
    }

    &-loading {
      color: #60666b;
    }

    &-add {
      color: $primary;
      width: fit-content;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
