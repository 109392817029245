var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "save-location" }, [
    _c(
      "div",
      { staticClass: "save-location-list" },
      _vm._l(_vm.outputLocations, function(location) {
        return _c(
          "div",
          {
            key: location.key,
            staticClass: "save-location-list-item",
            class: {
              selected: _vm.value === location.key,
              disabled: location.isUpload && !location.connected
            },
            on: {
              click: function() {
                return _vm.handleSelect(location)
              }
            }
          },
          [
            _c("Checkbox", {
              staticClass: "save-location-list-item-radio",
              attrs: { value: _vm.value === location.key, type: "radio" }
            }),
            _c("div", { staticClass: "save-location-list-item-info" }, [
              _c("p", { staticClass: "save-location-list-item-title" }, [
                _vm._v(_vm._s(location.text))
              ]),
              _c("p", { staticClass: "save-location-list-item-description" }, [
                _vm._v(" " + _vm._s(location.description) + " ")
              ])
            ]),
            _c("img", {
              staticClass: "save-location-list-item-img",
              attrs: { src: location.icon, alt: "" }
            }),
            _c("UploadDownload", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: location.isUpload && !location.connected,
                  expression: "location.isUpload && !location.connected"
                }
              ],
              staticClass: "save-location-list-item-connect",
              on: {
                connected: function() {
                  return _vm.handleSelect(location)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var connect = ref.connect
                      return [
                        _c("Button", {
                          attrs: { text: "Connect" },
                          on: {
                            click: function() {
                              return connect(true)
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }