<template>
  <b-modal active @close="close">
    <div class="meeting-export">
      <div class="meeting-export-header">
        <p class="meeting-export-header-title">Export generated content</p>
        <Button icon="close" size="xs" type="grey" @click="close" />
      </div>
      <div class="meeting-export-content" :class="{ padded: step < 2 }">
        <div v-if="!step" class="meeting-export-select">
          <p class="meeting-export-select-header">
            Select the content you want to export
          </p>
          <div class="meeting-export-select-list">
            <div
              v-for="output in outputs"
              :key="`output-select-${output.uuid}`"
              class="meeting-export-select-list-item"
              :class="{ disabled: loading.includes(output.uuid) }"
              @click="() => handleSelect(output)"
            >
              <Checkbox
                :value="selected.includes(output.uuid)"
                class="meeting-export-select-list-item-check"
              />
              <img
                :src="
                  require(`@/assets/file_type/${
                    output.tool.type === 'presentation' ? 'powerpoint' : 'word'
                  }.svg`)
                "
                alt=""
                class="meeting-export-select-list-item-icon"
              />
              {{ output.name }}
              <img
                v-if="loading.includes(output.uuid)"
                src="@/assets/icons/spinner.svg"
                alt=""
                class="meeting-export-select-list-item-loading"
              />
            </div>
          </div>
        </div>
        <div v-else-if="step === 1" class="meeting-export-select">
          <p class="meeting-export-select-header">
            Choose where you would like to save your assets
          </p>
          <OutputLocationProp
            :value="exportLocation"
            @input="handleLocationSelect"
          />
        </div>
        <MeetingExportResults
          v-else
          :outputs="selectedOutputs"
          :props-calls="propsCalls"
          :export-location="exportLocation"
        />
      </div>
      <div v-if="step < 2" class="meeting-export-footer">
        <div>
          <div v-if="!step" class="meeting-export-footer-selectall" @click="selectAll">
            <Checkbox
              :value="selected.length === outputs.length"
              :indeterminate="
                selected.length && selected.length !== outputs.length
              "
              class="meeting-export-footer-selectall-check"
            />
            {{
              selected.length === outputs.length ? 'Deselect all' : 'Select all'
            }}
          </div>
        </div>
        <div class="meeting-export-footer-btns">
          <Button :text="step ? 'Back' : 'Cancel'" type="white" @click="back" />
          <Button
            v-if="!step"
            text="Next"
            :disabled="!selected.length"
            @click="() => step++"
          />
          <Button v-else text="Export" @click="startExport" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Checkbox from '@c/library/Checkbox.vue'
import OutputLocationProp from './outputs/props/OutputLocationProp.vue'
import MeetingExportResults from './export/MeetingExportResults.vue'

export default {
  name: 'MeetingExportModal',
  components: {
    Button,
    Checkbox,
    OutputLocationProp,
    MeetingExportResults
  },
  props: {
    meeting: {
      type: Object,
      default: () => ({})
    },
    outputs: {
      type: Array,
      default: () => []
    },
    exportUrls: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selected: [],
    step: 0,
    exportLocation: ''
  }),
  computed: {
    selectedOutputs() {
      return this.outputs.filter((output) => this.selected.includes(output.uuid))
    },
    propsCalls() {
      return this.selected.reduce((acc, uuid) => {
        const o = this.outputs.find((t) => t.uuid === uuid)
        acc[uuid] = async () => ({
          name: `${this.meeting.account.name} ${o.name}.${o.tool.type === 'presentation' ? 'pptx' : 'docx'}`,
          signed_url: this.exportUrls[uuid]
        })
        return acc
      }, {})
    }
  },
  watch: {
    loading: {
      handler(newVal, oldVal) {
        const newLoaded = (oldVal || []).filter((uuid) => !newVal.includes(uuid))
        this.selected = [
          ...this.selected,
          ...newLoaded.filter((uuid) => !this.selected.includes(uuid))
        ]
      },
      immediate: true
    }
  },
  created() {
    this.selected = this.outputs
      .filter((output) => !this.loading.includes(output.uuid))
      .map((output) => output.uuid)
  },
  methods: {
    back() {
      if (!this.step) {
        this.close()
      } else {
        this.step--
      }
    },
    close() {
      this.$emit('close')
    },
    handleLocationSelect(location) {
      this.exportLocation = location
    },
    handleSelect(output) {
      this.selected = this.selected.includes(output.uuid)
        ? this.selected.filter((uuid) => uuid !== output.uuid)
        : [...this.selected, output.uuid]
    },
    startExport() {
      this.step++
    },
    selectAll() {
      this.selected =
        this.selected.length === this.outputs.length
          ? []
          : this.outputs
              .filter((output) => !this.loading.includes(output.uuid))
              .map((output) => output.uuid)
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-export {
  width: min(45rem, 90vw);
  background: white;
  border-radius: 8px;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  &-content {
    &.padded {
      padding: 2rem;
    }
  }

  &-select {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-header {
      color: #60666b;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.08);
      background: #f1f2f3;
      max-height: 50vh;
      overflow: auto;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(#000, 0.08);
        }

        &:hover {
          background: rgba(#000, 0.02);
        }

        &-check {
          pointer-events: none;
        }

        &-icon {
          height: 1.2rem;
          margin-left: 0.5rem;
        }

        &-loading {
          height: 1.2rem;
          animation: spin 1s linear infinite;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 2rem;
    border-top: 1px solid rgba(#000, 0.08);

    &-selectall {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      user-select: none;

      &-check {
        pointer-events: none;
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
