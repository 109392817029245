<template>
  <Tabs
    :tabs="tabs"
    :selected="(t) => t.key === tab"
    :max-height="maxHeight"
    :full-width="true"
    @select="selectTab"
  >
    <template #trigger="{ tab: t }">
      <div class="meeting-suggestions-trigger">
        {{ t.label }}
        <Tag
          v-if="
            !suggestionsLoading.includes(t.key) &&
            (t.key === 'offerings' || !regenRefInsp)
          "
          :text="`${suggestionLengths[t.key]}`"
          size="xxs"
          type="light-solid"
        />
        <img
          v-else
          src="@/assets/icons/spinner.svg"
          alt=""
          class="meeting-suggestions-trigger-loading"
        />
      </div>
    </template>
    <template #tab="{ tab: t }">
      <MeetingORI
        :key="t.key === 'offerings' ? t.key : `${t.key}-${meetingORIKey}`"
        :type="t.key"
        :selected="selected[t.key]"
        :meeting="meeting"
        :disabled="loading"
        :refresh-inputs="tab === t.key"
        class="meeting-suggestions-tab"
        @suggestions="(s) => loadSuggestions(t.key, s)"
        @input="(i) => handleEditMeeting(t, i)"
        @remove="(i) => handleEditMeeting(t, i, true)"
        @loading="(val) => setLoading(t, val)"
        @detail="(item) => $emit('detail', item, t.key)"
      />
    </template>
  </Tabs>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import Tabs from '@c/library/Tabs.vue'
import MeetingORI from '../MeetingORI.vue'
import { editMeeting } from '@/services/meetingService'

export default {
  name: 'NewMeetingSuggestions',
  components: { Tag, Tabs, MeetingORI },
  props: {
    meeting: {
      type: Object,
      default: () => ({})
    },
    maxHeight: {
      type: String,
      default: ''
    },
    hideTabs: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    tab: 'offerings',
    offeringsSuggestionsLength: 0,
    referencesSuggestionsLength: 0,
    inspirationsSuggestionsLength: 0,
    suggestionsLoading: [],
    loading: false,
    regenRefInsp: false,
    meetingORIKey: 0,
    tabs: []
  }),
  computed: {
    selectedOfferings() {
      return this.meeting?.offerings || []
    },
    selectedReferences() {
      return this.meeting?.references || []
    },
    selectedInspirations() {
      return this.meeting?.inspirations || []
    },
    suggestionLengths() {
      return {
        offerings: this.offeringsSuggestionsLength,
        references: this.referencesSuggestionsLength,
        inspirations: this.inspirationsSuggestionsLength
      }
    },
    selected() {
      return {
        offerings: this.selectedOfferings,
        references: this.selectedReferences,
        inspirations: this.selectedInspirations
      }
    }
  },
  created() {
    this.setTabs()
  },
  methods: {
    setTabs() {
      const tabs = [
        {
          key: 'offerings',
          label: 'Offerings'
        },
        {
          key: 'references',
          label: 'Cases'
        },
        {
          key: 'inspirations',
          label: 'Inspiration'
        }
      ]
      this.tabs = tabs.filter((t) => !this.hideTabs.includes(t.key))
      this.tab = this.tabs[0].key
    },
    selectTab(t) {
      this.tab = t.key
      if (t.key !== 'offerings' && this.regenRefInsp) {
        this.regenRefInsp = false
        this.meetingORIKey++
      }
    },

    loadSuggestions(type, suggestions) {
      if (type !== 'offerings' && this.regenRefInsp) {
        this.clearSuggestions(type)
        return
      }
      this[`${type}SuggestionsLength`] = suggestions.length
      this.$emit('suggestions', suggestions, type)
    },

    clearSuggestions(type) {
      this[`${type}SuggestionsLength`] = 0
    },

    setLoading(tab, val) {
      this.suggestionsLoading = val
        ? [...this.suggestionsLoading, tab.key]
        : this.suggestionsLoading.filter((t) => t !== tab.key)
    },

    async handleEditMeeting(tab, value, remove = false) {
      try {
        this.$emit('loading', true)
        this.loading = true
        let values = this.meeting[tab.key] || []
        if (remove) {
          values = values.filter((item) => item.uuid !== value.uuid)
        } else {
          values = [...values, value]
        }

        const meeting = await editMeeting({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          [`${tab.key.slice(0, -1)}_ids`]: values.map((val) => val.uuid)
        })
        this.$emit('edit', meeting)
        this.tabs.forEach((t) => {
          this[`${t.key}SuggestionsLength`] = meeting.suggestions?.[t.key]
            ? meeting.suggestions?.[t.key].length
            : this[`${t.key}SuggestionsLength`] || 0
        })
        if (tab.key === 'offerings') this.regenRefInsp = true
      } catch (e) {
        this.$console.debug('Error editing meeting', e)
        this.$toast.error(e, `editing meeting ${this.typeName}`)
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-suggestions {
  &-trigger {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-loading {
      height: 1.2rem;
      animation: spin 1s linear infinite;
    }
  }

  &-tab {
    padding-top: 1rem;
  }
}

::v-deep .utabs {
  z-index: 99 !important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
