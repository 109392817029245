<template>
  <div class="custom">
    <InlineSearch
      ref="customorisearch"
      :placeholder="searchPlaceholder"
      :search-function="searchFunction"
      :prop-mapping="propMapping"
      :show-collect="true"
      :position-fixed="true"
      :refresh-inputs="refreshInputs"
      v-on="$listeners"
    >
      <template #item="{ item, select }">
        <MeetingContentCard
          :item="item"
          :selected="[]"
          :bordered="false"
          class="custom-card"
          @add="() => select(item)"
        />
      </template>
    </InlineSearch>
  </div>
</template>

<script>
import InlineSearch from '@c/library/InlineSearch.vue'
import { mapActions } from 'vuex'
import MeetingContentCard from '../content/MeetingContentCard.vue'

export default {
  name: 'MeetingORICustom',
  components: { InlineSearch, MeetingContentCard },
  props: {
    type: {
      type: String,
      default: 'offerings',
      validator: (value) => {
        return ['', 'inspirations', 'offerings', 'references'].includes(value)
      }
    },
    refreshInputs: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    searchPlaceholder() {
      const placeholderMap = {
        inspirations: 'Search inspirational content...',
        offerings: 'Search offerings...',
        references: 'Search cases...'
      }
      return placeholderMap[this.type]
    },
    searchFunction() {
      const searchFunctionMap = {
        inspirations: this.searchInspirations,
        offerings: this.searchOfferings,
        references: this.searchReferences
      }
      return (search) => {
        const searchFunction = searchFunctionMap[this.type]({
          workspace_id: this.$route.params.workspace_id,
          search
        })
        return new Promise((res, rej) => {
          searchFunction
            .then(response => {
              res(response.filter(item => item.status === 'published'))
            })
            .catch(error => {
              rej(error)
            })
        })
      }
    },
    propMapping() {
      return {
        image: 'image',
        name: this.type === 'inspirations' ? 'title' : 'name',
        summary: 'summary'
      }
    }
  },
  methods: {
    ...mapActions([
      'searchReferences',
      'searchOfferings',
      'searchInspirations'
    ]),
    blur() {
      this.$refs.customorisearch.blur()
    }
  }
}
</script>
<style scoped lang="scss">
.custom {
  &-card {
    background: none !important;
    cursor: pointer;
    padding: 0;
    width: 100%;
  }
}

::v-deep .custom-card {
  & button {
    opacity: 1 !important;
  }
}
</style>
