var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom" },
    [
      _c(
        "InlineSearch",
        _vm._g(
          {
            ref: "customorisearch",
            attrs: {
              placeholder: _vm.searchPlaceholder,
              "search-function": _vm.searchFunction,
              "prop-mapping": _vm.propMapping,
              "show-collect": true,
              "position-fixed": true,
              "refresh-inputs": _vm.refreshInputs
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var select = ref.select
                  return [
                    _c("MeetingContentCard", {
                      staticClass: "custom-card",
                      attrs: { item: item, selected: [], bordered: false },
                      on: {
                        add: function() {
                          return select(item)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }