<template>
  <div class="meeting-ori">
    <p class="meeting-ori-subtitle">{{ subtitle }}</p>
    <MeetingORICustom
      v-if="type"
      ref="meetingoricustom"
      :type="type"
      :refresh-inputs="refreshInputs"
      @submit="handleCustomSubmit"
    />
    <div v-if="selected && selected.length" class="meeting-ori-items">
      <MeetingContentCard
        v-for="item in selectedSorted"
        :key="item.explanation || item.uuid || item.name || item.title"
        :item="item"
        :selected="selected"
        :enable-subofferings="true"
        :inspectable="true"
        :disabled="disabled"
        class="meeting-ori-items-item"
        @remove="handleRemove"
        @click="(item) => $emit('detail', item)"
      />
    </div>
    <MeetingORISuggestions
      v-if="type"
      :type="type"
      :selected="selected"
      :suggestions="suggestions"
      :loading="suggestionsLoading"
      :error="suggestionsError"
      :disabled="disabled"
      @add="handleSubmit"
      @remove="handleRemove"
      @retry="handleSuggestionsError"
      @detail="(item) => $emit('detail', item)"
    />
  </div>
</template>

<script>
import {
  getMeetingInspirations,
  getMeetingOfferings,
  getMeetingReferences
} from '@/services/meetingService'
import MeetingContentCard from './content/MeetingContentCard.vue'
import MeetingORICustom from './ori/MeetingORICustom.vue'
import MeetingORISuggestions from './ori/MeetingORISuggestions.vue'

export default {
  name: 'MeetingORI',
  components: {
    MeetingORICustom,
    MeetingContentCard,
    MeetingORISuggestions
  },
  props: {
    type: {
      type: String,
      default: 'offerings',
      validator: (value) => {
        return ['', 'inspirations', 'offerings', 'references'].includes(value)
      }
    },
    selected: {
      type: Array,
      default: () => []
    },
    meeting: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    refreshInputs: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    suggestionsError: false,
    suggestionsLoading: false,
    suggestions: [],
    modalItem: null,
    loadingExplanation: []
  }),
  computed: {
    selectedSorted() {
      const parents = this.selected.filter((s) => !s.parent)
      const children = this.selected.filter((s) => s.parent)
      return [
        ...parents.map((p) => {
          p.subofferings = children.filter((c) => c.parent.uuid === p.uuid)
          return p
        }),
        ...children.filter(
          (c) => !parents.some((p) => p.uuid === c.parent.uuid)
        )
      ]
    },
    subtitle() {
      const subtitleMap = {
        inspirations: 'Add inspirational content for this client',
        offerings: 'Add the offerings you think are relevant for this client',
        references: 'Add cases for this client'
      }
      return subtitleMap[this.type]
    },
    meetingSuggestions() {
      return this.meeting?.suggestions?.[this.type] || []
    }
  },
  watch: {
    meetingSuggestions(newVal) {
      if (
        this.type &&
        (newVal?.length || 0) !== (this.suggestions?.length || 0)
      )
        this.suggestions = newVal
    }
  },
  created() {
    if (this.type) this.loadSuggestions()
  },
  methods: {
    handleSubmit(e) {
      this.$emit('input', e)
    },
    handleCustomSubmit(e) {
      this.handleSubmit(e)
    },
    handleRemove(item) {
      this.$emit('remove', item)
    },
    async loadSuggestions() {
      this.suggestionsLoading = true
      this.$emit('loading', true)
      try {
        if (this.meeting?.suggestions?.[this.type]?.length) {
          this.suggestions = this.meeting?.suggestions?.[this.type]
        } else {
          this.suggestions = []
          const suggestionFunction = {
            offerings: getMeetingOfferings,
            references: getMeetingReferences,
            inspirations: getMeetingInspirations
          }[this.type]
          this.suggestions = await suggestionFunction({
            workspace_id: this.$route.params.workspace_id,
            story_id: this.meeting.uuid
          })
          this.$emit('suggestions', this.suggestions, this.type)
        }
      } catch (e) {
        this.$console.debug('Error while generating challenges', e)
        this.suggestionsError = true
      } finally {
        this.suggestionsLoading = false
        this.$emit('loading', false)
      }
    },
    async handleSuggestionsError() {
      this.suggestionsError = false
      await this.loadSuggestions()
    },
    blur() {
      this.$refs.meetingoricustom.blur()
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-ori {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;

  &-items {
    display: flex;
    flex-flow: column nowrap;
    border-radius: 6px;
    border: 1px solid rgba(#000, 8%);
    overflow: hidden;

    &-item {
      &:not(:first-child) {
        border-top: 1px solid rgba(#000, 8%);
      }
    }
  }
}
</style>
