<template>
  <b-modal
    :active="visible"
    :can-cancel="loading ? [] : ['escape', 'outside']"
    :destroy-on-hide="false"
    @close="close"
  >
    <div class="meeting-changed">
      <div class="meeting-changed-header">
        <p class="meeting-changed-header-title">Meeting updated</p>
        <Button icon="close" type="grey" size="xs" @click="close" />
      </div>
      <div class="meeting-changed-content">
        <div class="meeting-changed-content-header">
          <img
            src="@/assets/icons/ai.svg"
            alt=""
            class="meeting-changed-content-header-icon"
          />
          {{ contentTitle }}
        </div>
        <NewMeetingSuggestions
          v-if="modalKey"
          :key="modalKey"
          :meeting="localMeeting"
          :hide-tabs="hideTabs"
          max-height="60vh"
          class="meeting-changed-content-suggestions"
          :class="{ visible: !step }"
          @loading="(l) => (loading = l)"
          @edit="handleEdit"
          @suggestions="(s, t) => $emit('suggestions', s, t)"
          @detail="(item, type) => $emit('detail', item, type)"
        />
        <div v-if="step" class="meeting-changed-content-regen">
          <div
            v-for="output in dynamicOutputs"
            :key="`output-select-${output.uuid}`"
            class="meeting-changed-content-regen-item"
            @click="() => handleSelect(output)"
          >
            <Checkbox
              :value="selected.includes(output.uuid)"
              class="meeting-changed-content-regen-item-check"
            />
            <img
              :src="
                require(`@/assets/file_type/${
                  output.tool.type === 'presentation' ? 'powerpoint' : 'word'
                }.svg`)
              "
              alt=""
              class="meeting-changed-content-regen-item-icon"
            />
            {{ output.name }}
          </div>
        </div>
      </div>
      <div class="meeting-changed-footer">
        <Button
          :text="step ? 'Finish' : 'Next'"
          :loading="loading"
          icon="chevron-right-medium"
          @click="next"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Checkbox from '@c/library/Checkbox.vue'
import NewMeetingSuggestions from './changed/NewMeetingSuggestions.vue'

export default {
  name: 'MeetingChangedModal',
  components: { Button, Checkbox, NewMeetingSuggestions },
  props: {
    meeting: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    hideTabs: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    step: 0,
    localMeeting: {},
    selected: [],
    loading: false,
    modalKey: 0,
    edited: false
  }),
  computed: {
    contentTitle() {
      return this.step
        ? 'Would you like to regenerate some of your outputs with the updates made to your meeting?'
        : 'Your meeting was updated and our AI has noticed. We have some new suggestions for you.'
    },
    outputs() {
      return this.localMeeting.outputs.filter((o) => !o.date_deleted)
    },
    dynamicOutputs() {
      return this.outputs.filter((o) => o.tool?.dynamic)
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.step = 0
        this.selected = []
        this.localMeeting = { ...this.meeting }
        this.modalKey++
      }
    }
  },
  created() {
    this.localMeeting = { ...this.meeting }
  },
  methods: {
    close() {
      if (!this.step) this.step++
      else if (this.edited) this.finish()
      else this.$emit('close')
    },
    next() {
      if (!this.step) this.step++
      else this.finish()
    },
    finish() {
      this.edited = false
      this.$emit('submit', {
        meeting: this.localMeeting,
        outputs: this.selected.map((s) =>
          this.outputs.find((o) => o.uuid === s)
        )
      })
    },
    handleSelect(output) {
      this.selected = this.selected.includes(output.uuid)
        ? this.selected.filter((uuid) => uuid !== output.uuid)
        : [...this.selected, output.uuid]
    },
    handleEdit(meeting) {
      this.edited = true
      this.localMeeting = meeting
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-changed {
  background: white;
  border-radius: 8px;
  width: min(75rem, 95vw);

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5rem;
    padding: 1.5rem;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      border-radius: 4px;
      border: 1px solid $primary;
      background: #ebf1ff;
      color: $primary;
      font-weight: 600;

      &-icon {
        height: 1.2rem;
        filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
          saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
      }
    }

    &-suggestions {
      &:not(.visible) {
        display: none;
      }
    }

    &-regen {
      display: flex;
      flex-flow: column nowrap;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.08);
      background: #f1f2f3;
      margin: 0 7.5rem;
      max-height: 60vh;
      overflow-y: auto;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(#000, 0.08);
        }

        &:hover {
          background: rgba(#000, 0.02);
        }

        &-check {
          pointer-events: none;
        }

        &-icon {
          height: 1.2rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
