var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "utabs-wrapper",
      class: { scrollable: !!_vm.maxHeight },
      style: Object.assign(
        {},
        _vm.maxHeight ? { maxHeight: _vm.maxHeight } : {},
        _vm.padding ? { "--utab-padding": _vm.padding } : {}
      )
    },
    [
      !_vm.hideTabs
        ? _c(
            "div",
            {
              staticClass: "utabs",
              class:
                ((_obj = {}),
                (_obj[_vm.type] = true),
                (_obj["full-width"] = _vm.fullWidth),
                _obj)
            },
            [
              _vm._l(_vm.tabs, function(tab, idx) {
                return _c(
                  "div",
                  {
                    key: "tab-" + idx,
                    staticClass: "utabs-tab",
                    class: {
                      selected: _vm.selected(tab),
                      "full-width": _vm.fullWidth
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("select", tab)
                      }
                    }
                  },
                  [
                    _vm._t(
                      "trigger",
                      function() {
                        return [
                          tab.icon
                            ? _c("img", {
                                staticClass: "utabs-tab-icon",
                                attrs: {
                                  src: require("@/assets/icons/" +
                                    tab.icon +
                                    ".svg"),
                                  alt: ""
                                }
                              })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(tab.name) + " ")
                        ]
                      },
                      { tab: tab, idx: idx }
                    )
                  ],
                  2
                )
              }),
              _c("div", { staticClass: "utabs-btns" }, [_vm._t("buttons")], 2)
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "utabs-content" },
        _vm._l(_vm.tabs, function(tab, idx) {
          return _c(
            "div",
            {
              key: "tabcontent-" + idx,
              staticClass: "utabs-content-tab",
              class: { selected: _vm.selected(tab) }
            },
            [_vm._t("tab", null, { tab: tab, idx: idx })],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }