var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overview" },
    _vm._l(_vm.blocks, function(block, idx) {
      return _c(
        "div",
        {
          key: "overview-block-" + idx,
          staticClass: "overview-block",
          class: { disabled: _vm.loading }
        },
        [
          _c(
            "div",
            { staticClass: "overview-block-header" },
            [
              _c("p", { staticClass: "overview-block-header-title" }, [
                _vm._v(_vm._s(block.name))
              ]),
              block.suggestions
                ? _c("Button", {
                    attrs: {
                      text: block.suggestions,
                      size: "xxs",
                      type: "light-solid"
                    },
                    on: {
                      click: function() {
                        return _vm.handleEdit(block)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "overview-block-header-edit-wrapper" },
                [
                  block.key !== "account"
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: "Edit meeting " + block.name.toLowerCase(),
                            type: "is-dark",
                            position: "is-right",
                            "append-to-body": true
                          }
                        },
                        [
                          _c("Button", {
                            staticClass: "overview-block-header-edit",
                            attrs: { icon: "edit", size: "xs", type: "grey" },
                            on: {
                              click: function() {
                                return _vm.handleEdit(block)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          block.items.length
            ? _c(
                "ul",
                { staticClass: "overview-block-list" },
                _vm._l(block.items, function(item, i) {
                  return _c(
                    "li",
                    {
                      key: "overview-block-item-" + i,
                      staticClass: "overview-block-list-item",
                      on: {
                        click: function() {
                          return _vm.handleSelect(block, item)
                        }
                      }
                    },
                    [
                      block.key === "notes"
                        ? _c(
                            "p",
                            { staticClass: "overview-block-list-item-dot" },
                            [_vm._v(" • ")]
                          )
                        : _vm._e(),
                      !block.hideImage
                        ? _c("Avatar", {
                            attrs: {
                              user: { avatar: item.image },
                              size: "xs",
                              "fallback-icon": block.fallbackIcon
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "overview-block-list-item-text" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "overview-block-list-item-text-header",
                              class: { bold: item.subtext }
                            },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          ),
                          item.subtext
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "overview-block-list-item-subtext"
                                },
                                [_vm._v(" " + _vm._s(item.subtext) + " ")]
                              )
                            : _vm._e()
                        ]
                      ),
                      block.linkedin
                        ? _c(
                            "div",
                            { staticClass: "overview-block-list-item-scrim" },
                            [
                              _c("img", {
                                staticClass:
                                  "overview-block-list-item-scrim-logo",
                                attrs: {
                                  src: require("@/assets/logo/linkedin.svg"),
                                  alt: ""
                                }
                              }),
                              _c("img", {
                                staticClass:
                                  "overview-block-list-item-scrim-arrow",
                                attrs: {
                                  src: require("@/assets/icons/arrow-right.svg"),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          !block.items.length
            ? _c("div", { staticClass: "overview-block-empty" }, [
                !_vm.loading
                  ? _c(
                      "p",
                      {
                        staticClass: "overview-block-add",
                        on: {
                          click: function() {
                            return _vm.handleEdit(block)
                          }
                        }
                      },
                      [_vm._v(" Add ")]
                    )
                  : _c("p", { staticClass: "overview-block-loading" }, [
                      _vm._v("Loading...")
                    ])
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }