<template>
  <div class="meeting-outputs">
    <div class="meeting-outputs-add">
      <div
        class="meeting-outputs-add-btn"
        :class="{ loading }"
        @click="() => $emit('add')"
      >
        Add asset
        <img
          :src="
            require(`@/assets/icons/${loading ? 'spinner' : 'plus-medium'}.svg`)
          "
          alt=""
          class="meeting-outputs-add-btn-icon"
          :class="{ loading }"
        />
      </div>
    </div>
    <div class="meeting-outputs-list">
      <div
        v-for="(block, idx) in outputBlocks"
        :key="`output-blocks-${idx}`"
        class="meeting-outputs-list-block"
      >
        <p class="meeting-outputs-list-block-title">{{ block.title }}</p>
        <div
          v-for="output in block.outputs"
          :key="output.uuid"
          class="meeting-outputs-item"
          :class="{
            selected: selected && selected.uuid === output.uuid,
            disabled: loading.length
          }"
          @click="() => $emit('select', output)"
        >
          <img
            :src="
              require(`@/assets/file_type/${
                output.tool && output.tool.type === 'presentation'
                  ? 'powerpoint'
                  : 'word'
              }.svg`)
            "
            alt=""
            class="meeting-outputs-item-icon"
          />
          <p class="meeting-outputs-item-title">
            {{ output.name }}
          </p>
          <b-tooltip
            v-if="output.tool && !output.tool.dynamic"
            label="Fixed - cannot be edited"
            type="is-dark"
          >
            <img
              src="@/assets/icons/lock-filled.svg"
              alt=""
              class="meeting-outputs-item-static"
            />
          </b-tooltip>
          <img
            v-if="contentLoading.includes(output.uuid)"
            src="@/assets/icons/spinner.svg"
            alt=""
            class="meeting-outputs-item-loading"
          />
          <MeetingOutputActions
            :output="output"
            :remove-loading="removeLoading.includes(output.uuid)"
            :disabled="contentLoading.includes(output.uuid)"
            class="meeting-outputs-item-btn"
            v-on="$listeners"
            @click.native.stop
          />
        </div>
      </div>
      <div v-if="loading" class="meeting-outputs-item">
        <b-skeleton height="1.5rem" width="1.5rem" />
        <b-skeleton height="1.2rem" width="15rem" />
      </div>
      <div v-if="loading" class="meeting-outputs-item">
        <b-skeleton height="1.5rem" width="1.5rem" />
        <b-skeleton height="1.2rem" width="15rem" />
      </div>
    </div>
    <div class="meeting-outputs-export">
      <Button
        text="Export"
        icon="download"
        :icon-left="true"
        :full-width="true"
        @click="() => $emit('export')"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import MeetingOutputActions from './MeetingOutputActions.vue'

export default {
  name: 'MeetingOutputsSidebar',
  components: { Button, MeetingOutputActions },
  props: {
    outputs: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    contentLoading: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: () => undefined
    },
    removeLoading: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    outputBlocks() {
      const d = (o) =>
        o.date_updated || o.date_created || new Date().toISOString()
      let today = this.outputs.filter(
        (o) => new Date(d(o)) > new Date(new Date().setHours(0, 0, 0, 0))
      )
      let yesterday = this.outputs.filter(
        (o) =>
          new Date(d(o)) >
            new Date(
              new Date(new Date().setHours(0, 0, 0, 0)).setDate(
                new Date().getDate() - 1
              )
            ) && new Date(d(o)) < new Date(new Date().setHours(0, 0, 0, 0))
      )
      let lastWeek = this.outputs.filter(
        (o) =>
          new Date(d(o)) >
            new Date(new Date().setDate(new Date().getDate() - 7)) &&
          new Date(d(o)) <
            new Date(
              new Date(new Date().setHours(0, 0, 0, 0)).setDate(
                new Date().getDate() - 1
              )
            )
      )
      let older = this.outputs.filter(
        (o) =>
          new Date(d(o)) <
          new Date(new Date().setDate(new Date().getDate() - 7))
      )
      today = today.sort((a, b) => new Date(d(b)) - new Date(d(a)))
      yesterday = yesterday.sort((a, b) => new Date(d(b)) - new Date(d(a)))
      lastWeek = lastWeek.sort((a, b) => new Date(d(b)) - new Date(d(a)))
      older = older.sort((a, b) => new Date(d(b)) - new Date(d(a)))
      return [
        ...(today.length ? [{ title: 'Today', outputs: today }] : []),
        ...(yesterday.length
          ? [{ title: 'Yesterday', outputs: yesterday }]
          : []),
        ...(lastWeek.length ? [{ title: 'Last week', outputs: lastWeek }] : []),
        ...(older.length ? [{ title: 'Older', outputs: older }] : [])
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-outputs {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &-list {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;

    &-block {
      &-title {
        font-weight: 600;
        padding: 0.75rem 1rem;
        color: #60666b;
        font-weight: 600;
        background: #f1f2f3;
        border-bottom: 1px solid rgba(#000, 0.08);
      }
    }
  }

  &-item {
    border-bottom: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    border-left: 2px solid transparent;
    transition: background 0.2s, border-left 0.2s;

    &:not(.disabled) {
      cursor: pointer;
    }

    .disabled {
      pointer-events: none;
    }

    &:hover {
      background: rgba($primary, 0.04);
      border-left: 2px solid $primary;
    }

    &.selected {
      background: rgba($primary, 0.08);
      border-left: 2px solid $primary;
    }

    &-icon {
      height: 1.5rem;
    }

    &-static {
      display: block;
      height: 1.2rem;
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }

    &-loading {
      height: 1rem;
      animation: spin 1s infinite;
    }

    &-info {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;
    }

    &-title {
      font-weight: 600;
    }

    &-description {
      color: #60666b;
    }

    &-btn {
      margin-left: auto;
    }
  }

  &-add {
    padding: 0.75rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-btn {
      background: #ebf1ff;
      border-radius: 8px;
      padding: 0.75rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      color: $primary;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: darken(#ebf1ff, 3%);
      }

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }

      &-icon {
        height: 1.5rem;
        filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
          saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);

        &.loading {
          animation: spin 1s infinite;
        }
      }
    }
  }

  &-export {
    margin-top: auto;
    padding: 1rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
