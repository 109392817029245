var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        active: "",
        "can-cancel": _vm.loading ? false : ["escape", "x", "outside"]
      },
      on: { close: _vm.close }
    },
    [
      _c(
        "div",
        { staticClass: "autocomplete-wrapper", class: { error: _vm.error } },
        [
          _c(
            "div",
            {
              ref: "autocompleteloading",
              staticClass: "autocomplete",
              on: { scroll: _vm.checkAutoScroll }
            },
            [
              _c("div", { staticClass: "autocomplete-header" }, [
                _c(
                  "div",
                  { staticClass: "autocomplete-header-animation-wrapper" },
                  [
                    _c("div", {
                      staticClass: "autocomplete-header-animation-glow one"
                    }),
                    _c("div", {
                      staticClass: "autocomplete-header-animation-glow two"
                    }),
                    _c("div", {
                      staticClass: "autocomplete-header-animation-glow three"
                    }),
                    _c("Animation", {
                      staticClass: "autocomplete-header-animation",
                      attrs: {
                        name: "logo-wink",
                        "background-color": _vm.error ? "#303032" : "#115efb"
                      }
                    })
                  ],
                  1
                ),
                _c("p", { staticClass: "autocomplete-header-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.error
                          ? "Something went wrong"
                          : "Hang tight, I'm on it!"
                      ) +
                      " "
                  )
                ])
              ]),
              !_vm.error
                ? _c(
                    "div",
                    { staticClass: "autocomplete-content" },
                    _vm._l(_vm.visibleStatus, function(block, idx) {
                      return _c(
                        "div",
                        {
                          key: "autocomplete-status-block-" + idx,
                          staticClass: "autocomplete-content-block"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "autocomplete-content-block-header"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "autocomplete-content-block-header-title"
                                },
                                [_vm._v(" " + _vm._s(block.title) + " ")]
                              ),
                              !_vm.blockLoaded(block, idx)
                                ? _c("img", {
                                    staticClass:
                                      "autocomplete-content-block-header-loading",
                                    attrs: {
                                      src: require("@/assets/icons/spinner.svg"),
                                      alt: ""
                                    }
                                  })
                                : _c("img", {
                                    staticClass:
                                      "autocomplete-content-block-header-check",
                                    attrs: {
                                      src: require("@/assets/icons/check-circle-filled.svg"),
                                      alt: ""
                                    }
                                  })
                            ]
                          ),
                          block.items && block.items.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "autocomplete-content-block-list"
                                },
                                _vm._l(block.items, function(item, itemIdx) {
                                  return _c(
                                    "div",
                                    {
                                      key:
                                        "autocomplete-status-block-item-" +
                                        itemIdx,
                                      staticClass:
                                        "autocomplete-content-block-list-item"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "autocomplete-content-block-list-item-header"
                                        },
                                        [
                                          item.image
                                            ? _c("Avatar", {
                                                attrs: {
                                                  user: { avatar: item.image },
                                                  size: "xs"
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "autocomplete-content-block-list-item-header-title"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              )
                                            ]
                                          ),
                                          item.is_available === null
                                            ? _c("img", {
                                                staticClass:
                                                  "autocomplete-content-block-list-item-header-loading",
                                                attrs: {
                                                  src: require("@/assets/icons/spinner.svg"),
                                                  alt: ""
                                                }
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "autocomplete-content-block-list-item-header-status",
                                                class: {
                                                  colored: item.is_available
                                                },
                                                attrs: {
                                                  src: require("@/assets/icons/" +
                                                    (item.is_available
                                                      ? "check"
                                                      : "minus") +
                                                    "-circle-filled.svg"),
                                                  alt: ""
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : block.items && !block.items.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "autocomplete-content-block-list-item"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "autocomplete-content-block-list-item-header-title"
                                    },
                                    [_vm._v(" None ")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.error
                ? _c("div", { staticClass: "autocomplete-padding" })
                : _vm._e()
            ]
          ),
          _vm.error
            ? _c(
                "div",
                { staticClass: "autocomplete-footer" },
                [
                  _c("span", [
                    _vm._v("We couldn't finish setting up your meeting")
                  ]),
                  _c("Button", {
                    attrs: {
                      text: "Retry",
                      icon: "refresh",
                      type: "light-solid"
                    },
                    on: { click: _vm.retry }
                  })
                ],
                1
              )
            : !_vm.completed
            ? _c("div", { staticClass: "autocomplete-footer" }, [
                _c("span", [_vm._v("This could take 1-3 minutes")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.minutes) + "m " + _vm._s(_vm.seconds) + "s")
                ])
              ])
            : _c(
                "div",
                { staticClass: "autocomplete-footer" },
                [
                  _c("span", [_vm._v("Meeting preparations ready! 🎉")]),
                  _c("Button", {
                    attrs: { text: "Let's go", type: "light-solid" },
                    on: { click: _vm.close }
                  })
                ],
                1
              )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }