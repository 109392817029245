var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.showModal, "can-cancel": ["escape", "outside"] },
      on: { close: _vm.close }
    },
    [
      !_vm.step
        ? _c("div", { staticClass: "meeting-edit" }, [
            _c(
              "div",
              { staticClass: "meeting-edit-header" },
              [
                _c("p", { staticClass: "meeting-edit-header-text" }, [
                  _vm._v("Edit meeting " + _vm._s(_vm.typeName))
                ]),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: { click: _vm.close }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "meeting-edit-content",
                on: { scroll: _vm.handleContentScroll }
              },
              [
                _c(
                  _vm.editComponent,
                  _vm._b(
                    {
                      key: "meetingedit-" + _vm.editComponentKey,
                      ref: "meetingeditcomponent",
                      tag: "component",
                      on: {
                        input: function(item) {
                          return _vm.editFunction(item)
                        },
                        remove: function(item, is_account) {
                          return _vm.editFunction(item, true, false, is_account)
                        },
                        edit: function(item) {
                          return _vm.editFunction(item, false, true)
                        },
                        detail: function(item) {
                          return _vm.$emit("detail", item)
                        },
                        suggestions: function(s) {
                          return _vm.$emit("suggestions", s, _vm.type)
                        }
                      }
                    },
                    "component",
                    _vm.editProps,
                    false
                  )
                ),
                _c("b-loading", {
                  attrs: { active: _vm.loading, "is-full-page": false }
                })
              ],
              1
            )
          ])
        : _c("div", { staticClass: "meeting-edit" }, [
            _c(
              "div",
              { staticClass: "meeting-edit-header" },
              [
                _c("p", { staticClass: "meeting-edit-header-text" }, [
                  _vm._v("Regenerate content")
                ]),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: { click: _vm.close }
                })
              ],
              1
            ),
            _c("div", { staticClass: "meeting-edit-content" }, [
              _c("p", { staticClass: "meeting-edit-content-text" }, [
                _vm._v(
                  " You edited this meeting's " +
                    _vm._s(_vm.typeName) +
                    ". Would you like to regenerate the selected content with this new information? "
                )
              ]),
              _c(
                "div",
                { staticClass: "meeting-edit-content-list" },
                _vm._l(_vm.dynamicOutputs, function(output) {
                  return _c(
                    "div",
                    {
                      key: "output-" + output.uuid,
                      staticClass: "meeting-edit-content-list-item",
                      class: {
                        disabled: _vm.contentLoading.includes(output.uuid)
                      },
                      on: {
                        click: function() {
                          return _vm.selectRegenerate(output)
                        }
                      }
                    },
                    [
                      _c("Checkbox", {
                        staticClass: "meeting-edit-content-list-item-check",
                        attrs: {
                          value: _vm.regenerate.some(function(o) {
                            return o.uuid === output.uuid
                          })
                        }
                      }),
                      _c("img", {
                        staticClass: "meeting-edit-content-list-item-icon",
                        attrs: {
                          src: require("@/assets/file_type/" +
                            (output.tool.type === "presentation"
                              ? "powerpoint"
                              : "word") +
                            ".svg"),
                          alt: ""
                        }
                      }),
                      _c(
                        "p",
                        { staticClass: "meeting-edit-content-list-item-text" },
                        [_vm._v(" " + _vm._s(output.name) + " ")]
                      ),
                      _vm.contentLoading.includes(output.uuid)
                        ? _c("img", {
                            staticClass:
                              "meeting-edit-content-list-item-loading",
                            attrs: {
                              src: require("@/assets/icons/spinner.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticClass: "meeting-edit-footer" },
              [
                _c("Button", {
                  attrs: { text: "Cancel", type: "white" },
                  on: { click: _vm.close }
                }),
                _c("Button", {
                  attrs: { text: "Regenerate" },
                  on: { click: _vm.handleRegenerate }
                })
              ],
              1
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }