<template>
  <b-modal active @close="close">
    <div class="translate-output">
      <div class="translate-output-header">
        <p class="translate-output-header-title">
          Translate "{{ output.name }}"
        </p>
        <Button icon="close" type="grey" @click="close" />
      </div>
      <div class="translate-output-content">
        <p class="translate-output-content-text">
          What language would you like to translate this asset to?
        </p>
        <LanguageSelect v-model="selectedLanguage" />
      </div>
      <div class="translate-output-footer">
        <Button text="Cancel" type="white" @click="close" />
        <Button text="Translate" @click="translate" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import LanguageSelect from '@c/library/LanguageSelect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MeetingOutputTranslateModal',
  components: {
    Button,
    LanguageSelect
  },
  props: {
    output: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    selectedLanguage: ''
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'currentUser'])
  },
  created() {
    this.selectedLanguage =
      this.output?.tool.type === 'presentation'
        ? this.currentWorkspace.language
        : this.currentUser.language
  },
  methods: {
    close() {
      this.$emit('close')
    },
    translate() {
      this.$emit('translate', this.selectedLanguage)
    }
  }
}
</script>

<style lang="scss" scoped>
.translate-output {
  background: white;
  border-radius: 8px;
  width: min(30rem, 90vw);

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  &-content {
    padding: 1rem 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem 1.5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
