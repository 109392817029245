<template>
  <Dropdown :items="actions" :disabled="disabled">
    <template #trigger>
      <div class="output-actions" :class="{ loading: removeLoading }">
        <Button
          icon="kebab"
          type="grey"
          size="xs"
          class="output-actions-trigger"
        />
        <div class="output-actions-loading-wrapper">
          <img
            src="@/assets/icons/spinner.svg"
            alt=""
            class="output-actions-loading"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@c/library/Dropdown.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'MeetingOutputActions',
  components: { Dropdown, Button },
  props: {
    output: {
      type: Object,
      required: true
    },
    removeLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    actions() {
      return [
        {
          text: 'Translate',
          icon: 'language',
          callback: () => this.$emit('translate', this.output)
        },
        {
          text: 'Rename',
          icon: 'edit',
          callback: () => this.$emit('rename', this.output)
        },
        {
          text: 'Delete',
          icon: 'bin',
          filter: 'red',
          callback: () => this.$emit('delete', this.output)
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.output-actions {
  position: relative;

  &.loading {
    .output-actions-trigger {
      opacity: 0;
    }

    .output-actions-loading-wrapper {
      opacity: 0.75;
      height: unset;
      width: unset;
      overflow: unset;
    }
  }

  &-trigger {
    opacity: 1;
  }

  &-loading {
    display: block;
    height: 1.2rem;
    animation: spin 1s linear infinite;

    &-wrapper {
      opacity: 0;
      transition: opacity 0.3s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
