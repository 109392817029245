<template>
  <b-modal active @close="close">
    <div class="meeting-modal">
      <div class="meeting-modal-info">
        <p class="meeting-modal-info-title">{{ note.title }}</p>
      </div>
      <div class="meeting-modal-info">
        <div class="meeting-modal-author">
          Added by
          <Avatar
            :user="note.member || { username: 'Unknown user' }"
            size="xs"
          />
          <p class="meeting-modal-author-name">
            {{ note.member ? $umodel.full_name(note.member) : 'Unknown user' }}
          </p>
        </div>
        <p v-if="updated" class="meeting-modal-updated">
          Last updated {{ updated }}
        </p>
      </div>
      <p class="meeting-modal-content">{{ note.content }}</p>
    </div>
  </b-modal>
</template>

<script>
import { relativeTime } from '@/util'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'MeetingORIModal',
  components: { Avatar },
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    explanation: '',
    selectLoading: false
  }),
  computed: {
    updated() {
      let date =
        this.note?.date_updated || this.note?.date_created
          ? new Date(this.note?.date_updated || this.note?.date_created)
          : ''
      return date ? relativeTime(this.date) : ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-modal {
  width: min(50rem, 90vw);
  max-height: 90vh;
  overflow-y: auto;
  padding: 1.75rem;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-size: 1.75rem;
      font-weight: 700;
    }
  }

  &-updated {
    color: #60666b;
  }

  &-author {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: #60666b;
    gap: 0.5rem;

    &-name {
      color: #303032;
      font-weight: 600;
    }
  }

  &-content {
    color: #60666b;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(#000, 0.08);
    background: #f1f2f3;
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
