var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    { attrs: { active: _vm.visible }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "name-output" }, [
        _c(
          "div",
          { staticClass: "name-output-header" },
          [
            _c("p", { staticClass: "name-output-header-title" }, [
              _vm._v('Rename "' + _vm._s(_vm.outputName) + '"')
            ]),
            _c("Button", {
              attrs: { icon: "close", type: "grey" },
              on: { click: _vm.close }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "name-output-content" },
          [
            _c("TextInput", {
              attrs: { placeholder: "Edit output name" },
              on: { submit: _vm.rename },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "name-output-footer" },
          [
            _c("Button", {
              attrs: { text: "Cancel", type: "white", disabled: _vm.loading },
              on: { click: _vm.close }
            }),
            _c("Button", {
              attrs: {
                text: "Rename",
                disabled: _vm.name === _vm.outputName,
                loading: _vm.loading
              },
              on: { click: _vm.rename }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }