var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-outputs-header" },
    [
      _c("Button", {
        attrs: { icon: "arrow-left", type: "grey", size: "xs" },
        on: {
          click: function() {
            return _vm.$router.go(-1)
          }
        }
      }),
      _c("Avatar", {
        attrs: {
          user: { avatar: _vm.meetingImage },
          "fallback-icon": "company",
          size: "xs"
        }
      }),
      _vm.meeting && !_vm.loadingNameEdit
        ? _c(
            "div",
            {
              staticClass: "meeting-outputs-header-name",
              class: { clickable: !!_vm.meeting, editing: _vm.editingName },
              on: { click: _vm.startNameEdit }
            },
            [
              _c("p", { staticClass: "meeting-outputs-header-name-text" }, [
                _vm._v(" " + _vm._s(_vm.meeting && _vm.meeting.title) + " ")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title"
                  }
                ],
                ref: "meetingtitleinput",
                staticClass: "meeting-outputs-header-name-input",
                attrs: {
                  id: "",
                  type: "text",
                  name: "",
                  placeholder: "Meeting name"
                },
                domProps: { value: _vm.title },
                on: {
                  blur: _vm.finishNameEdit,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.title = $event.target.value
                  }
                }
              })
            ]
          )
        : _c(
            "div",
            { staticClass: "meeting-outputs-header-name" },
            [_c("b-skeleton", { attrs: { height: "1.2rem", width: "7rem" } })],
            1
          ),
      _c("p", { staticClass: "meeting-outputs-header-dot" }, [_vm._v("•")]),
      _vm.meeting
        ? _c("p", { staticClass: "meeting-outputs-header-date" }, [
            _vm._v(_vm._s(_vm.meetingDate))
          ])
        : _c(
            "div",
            { staticClass: "meeting-outputs-header-name" },
            [_c("b-skeleton", { attrs: { height: "1.2rem", width: "7rem" } })],
            1
          ),
      _c(
        "div",
        { staticClass: "meeting-outputs-header-btn" },
        [_vm._t("btns")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }