var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "meeting-modal" }, [
      _c(
        "div",
        { staticClass: "meeting-modal-info" },
        [
          _c("p", { staticClass: "meeting-modal-info-title" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c("Button", {
            attrs: {
              text: _vm.linkButtonText,
              icon: "internal",
              type: "light"
            },
            on: { click: _vm.gotoItem }
          })
        ],
        1
      ),
      _vm.updated
        ? _c("p", { staticClass: "meeting-modal-updated" }, [
            _vm._v(" Last updated " + _vm._s(_vm.updated) + " ")
          ])
        : _vm._e(),
      _vm.attributes.length
        ? _c(
            "div",
            { staticClass: "meeting-modal-attributes" },
            _vm._l(_vm.attributes, function(a) {
              return _c("Tag", {
                key: a,
                attrs: { text: a, type: "light", size: "xs" }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.image || _vm.summary
        ? _c("div", { staticClass: "meeting-modal-content" }, [
            _vm.image
              ? _c("img", {
                  staticClass: "meeting-modal-image",
                  attrs: { src: _vm.image }
                })
              : _vm._e(),
            _vm.summary
              ? _c("p", { staticClass: "meeting-modal-summary" }, [
                  _vm._v(_vm._s(_vm.summary))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "meeting-modal-explanation" },
        [
          _c("p", { staticClass: "meeting-modal-explanation-header" }, [
            _c("img", {
              staticClass: "meeting-modal-explanation-header-icon",
              class: { loading: _vm.loading },
              attrs: { src: require("@/assets/icons/ai.svg"), alt: "" }
            }),
            _vm._v(" Explanation ")
          ]),
          _vm.loading
            ? _c("LoadingDots")
            : _vm.explanation
            ? _c("MarkdownEdit", {
                staticClass: "meeting-modal-explanation-content",
                attrs: { value: _vm.explanation }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }