var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "meeting-modal" }, [
      _c("div", { staticClass: "meeting-modal-info" }, [
        _c("p", { staticClass: "meeting-modal-info-title" }, [
          _vm._v(_vm._s(_vm.note.title))
        ])
      ]),
      _c("div", { staticClass: "meeting-modal-info" }, [
        _c(
          "div",
          { staticClass: "meeting-modal-author" },
          [
            _vm._v(" Added by "),
            _c("Avatar", {
              attrs: {
                user: _vm.note.member || { username: "Unknown user" },
                size: "xs"
              }
            }),
            _c("p", { staticClass: "meeting-modal-author-name" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.note.member
                      ? _vm.$umodel.full_name(_vm.note.member)
                      : "Unknown user"
                  ) +
                  " "
              )
            ])
          ],
          1
        ),
        _vm.updated
          ? _c("p", { staticClass: "meeting-modal-updated" }, [
              _vm._v(" Last updated " + _vm._s(_vm.updated) + " ")
            ])
          : _vm._e()
      ]),
      _c("p", { staticClass: "meeting-modal-content" }, [
        _vm._v(_vm._s(_vm.note.content))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }