<template>
  <div class="meeting-outputs-header">
    <Button
      icon="arrow-left"
      type="grey"
      size="xs"
      @click="() => $router.go(-1)"
    />
    <Avatar
      :user="{ avatar: meetingImage }"
      fallback-icon="company"
      size="xs"
    />
    <div
      v-if="meeting && !loadingNameEdit"
      class="meeting-outputs-header-name"
      :class="{ clickable: !!meeting, editing: editingName }"
      @click="startNameEdit"
    >
      <p class="meeting-outputs-header-name-text">
        {{ meeting && meeting.title }}
      </p>
      <input
        id=""
        ref="meetingtitleinput"
        v-model="title"
        type="text"
        name=""
        placeholder="Meeting name"
        class="meeting-outputs-header-name-input"
        @blur="finishNameEdit"
      />
    </div>
    <div v-else class="meeting-outputs-header-name">
      <b-skeleton height="1.2rem" width="7rem" />
    </div>
    <p class="meeting-outputs-header-dot">&bull;</p>
    <p v-if="meeting" class="meeting-outputs-header-date">{{ meetingDate }}</p>
    <div v-else class="meeting-outputs-header-name">
      <b-skeleton height="1.2rem" width="7rem" />
    </div>
    <div class="meeting-outputs-header-btn">
      <slot name="btns"> </slot>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import { formatDate } from '@/util'
import { editMeeting } from '@/services/meetingService'

export default {
  name: 'MeetingOutputsHeader',
  components: { Button, Avatar },
  props: {
    meeting: {
      type: Object,
      default: () => undefined
    }
  },
  data: () => ({
    title: '',
    editingName: false,
    loadingNameEdit: false
  }),
  computed: {
    meetingImage() {
      return (
        this.meeting?.account?.organisation?.icon ||
        this.meeting?.account?.organisation?.logo ||
        ''
      )
    },
    meetingDate() {
      return this.meeting?.date_updated
        ? formatDate(this.meeting?.date_updated)
        : this.meeting?.date_created
        ? formatDate(this.meeting?.date_created)
        : ''
    }
  },
  watch: {
    meeting: {
      handler() {
        this.title = this.meeting?.title || ''
      },
      immediate: true
    }
  },
  methods: {
    startNameEdit() {
      if (!this.meeting) return
      this.editingName = true
      this.$refs.meetingtitleinput.focus()
    },
    async finishNameEdit() {
      try {
        if (this.title === this.meeting.title) {
          this.editingName = false
          return
        }
        this.loadingNameEdit = true
        const res = await editMeeting({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          title: this.title
        })
        this.$emit('edit', res)
      } catch (e) {
        this.$console.debug('Error editing meeting name', e)
        this.$toast.error(e, 'editing meeting name')
      } finally {
        this.loadingNameEdit = false
        this.editingName = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-outputs-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(#000, 0.08);

  &-name {
    position: relative;
    border-radius: 4px;
    border: 2px solid transparent;
    position: relative;

    &:not(.editing).clickable {
      cursor: text;

      &:hover {
        border: 2px solid $primary;
      }
    }

    &.editing {
      border: 2px solid $primary;

      & .meeting-outputs-header-name-text {
        opacity: 0;
      }

      & .meeting-outputs-header-name-input {
        width: 100%;
        opacity: 1;
        pointer-events: all;
      }
    }

    &-text {
      font-size: 1.15rem;
      font-weight: 600;
      padding: 2px 4px;
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      font-size: 1.15rem;
      font-weight: 600;
      border: none;
    }
  }

  &-dot {
    color: #8f9399;
  }

  &-date {
    font-size: 1rem;
    color: #8f9399;
  }

  &-btn {
    margin-left: auto;
  }
}
</style>
