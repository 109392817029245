var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-outputs" },
    [
      _c("MeetingOutputsHeader", {
        attrs: { meeting: _vm.meeting },
        on: { edit: _vm.handleMeetingEdit }
      }),
      _c(
        "div",
        {
          ref: "meetingoutputs",
          staticClass: "meeting-outputs-content",
          style: _vm.maxHeightStyle
        },
        [
          _c(
            "div",
            { staticClass: "meeting-outputs-overview" },
            [
              _c("MeetingOverviewSidebar", {
                key: _vm.overviewKey,
                attrs: { meeting: _vm.meeting, loading: _vm.initLoading },
                on: { select: _vm.openDetail, edit: _vm.openEdit }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "meeting-outputs-preview" },
            [
              _vm.initLoading
                ? _c(
                    "div",
                    { staticClass: "meeting-outputs-loading" },
                    [
                      _c("b-loading", {
                        attrs: { "is-full-page": false, active: "" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.initLoading ? [] : _vm.outputs, function(output) {
                return _c(
                  "div",
                  {
                    key: "output-preview-" + _vm.outputId(output),
                    staticClass: "meeting-outputs-preview-tab",
                    class: {
                      visible:
                        _vm.selectedOutput &&
                        _vm.outputId(_vm.selectedOutput) ===
                          _vm.outputId(output)
                    }
                  },
                  [
                    _c("MeetingOutputPreview", {
                      attrs: {
                        meeting: _vm.meeting,
                        output: output,
                        loading: _vm.outputContentLoading.includes(output.uuid),
                        "slide-regenerating": _vm.slideRegenerating,
                        error: !!_vm.outputContentError[output.uuid]
                      },
                      on: {
                        edit: _vm.setOutput,
                        regenerate: function(prompt, slide_index) {
                          return _vm.loadOutputContent(output, true, {
                            prompt: prompt,
                            slide_index: slide_index
                          })
                        }
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "meeting-outputs-outputs" },
            [
              _c("MeetingOutputsSidebar", {
                attrs: {
                  outputs: _vm.outputs,
                  selected: _vm.selectedOutput,
                  loading: _vm.initLoading,
                  "content-loading": _vm.outputContentLoading,
                  "remove-loading": _vm.removeLoading
                },
                on: {
                  select: _vm.selectOutput,
                  export: _vm.startExport,
                  add: _vm.startAddOutputs,
                  history: _vm.showOutputHistory,
                  translate: _vm.startOutputTranslate,
                  rename: _vm.startOutputRename,
                  delete: _vm.removeOutput
                }
              })
            ],
            1
          )
        ]
      ),
      _c("MeetingEditModal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.editModal,
            expression: "!!editModal"
          }
        ],
        attrs: {
          visible: !!_vm.editModal,
          meeting: _vm.meeting,
          type: _vm.editModal,
          outputs: _vm.outputs,
          selected: _vm.selectedOutput,
          "content-loading": _vm.outputContentLoading
        },
        on: {
          detail: function(item) {
            return _vm.openDetail({ key: _vm.editModal }, { item: item })
          },
          close: _vm.closeEdit,
          input: _vm.handleMeetingEdit,
          regenerate: _vm.handleRegenerate,
          suggestions: _vm.setMeetingSuggestions
        }
      }),
      _vm.exportModal
        ? _c("MeetingExportModal", {
            attrs: {
              meeting: _vm.meeting,
              outputs: _vm.outputs,
              "export-urls": _vm.exportUrls,
              loading: _vm.outputContentLoading
            },
            on: {
              close: function() {
                return (_vm.exportModal = false)
              }
            }
          })
        : _vm._e(),
      _vm.addModal
        ? _c("EditMeeting", {
            attrs: {
              visible: _vm.addModal,
              meeting: _vm.meeting,
              mode: "assets"
            },
            on: {
              close: function() {
                return (_vm.addModal = false)
              },
              assets: _vm.addOutputs
            }
          })
        : _vm._e(),
      _vm.translateModal
        ? _c("MeetingOutputTranslateModal", {
            attrs: { output: _vm.translateModal },
            on: {
              close: function() {
                return (_vm.translateModal = undefined)
              },
              translate: _vm.finishTranslate
            }
          })
        : _vm._e(),
      _c("MeetingOutputNameModal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.renameModal,
            expression: "!!renameModal"
          }
        ],
        attrs: { output: _vm.renameModal, visible: !!_vm.renameModal },
        on: {
          close: function() {
            return (_vm.renameModal = undefined)
          },
          rename: _vm.finishRename
        }
      }),
      _vm.showAutocomplete
        ? _c("MeetingAutocompleteLoading", {
            key: _vm.autocompleteKey,
            attrs: {
              meeting: _vm.meeting,
              loading: _vm.autocompleteLoading,
              error: _vm.autocompleteError || _vm.meetingError
            },
            on: {
              close: function() {
                return (_vm.showAutocomplete = false)
              },
              retry: _vm.retryInit
            }
          })
        : _vm._e(),
      !_vm.autocompleteLoading && _vm.meeting
        ? _c("MeetingChangedModal", {
            attrs: {
              meeting: _vm.meeting,
              visible: !!_vm.meetingChanged,
              "hide-tabs":
                _vm.meetingChanged === "offerings" ? ["offerings"] : []
            },
            on: {
              close: function() {
                return _vm.finishMeetingChanged({})
              },
              submit: _vm.finishMeetingChanged,
              suggestions: _vm.setMeetingSuggestions,
              detail: function(item, type) {
                return _vm.openDetail({ key: type }, { item: item })
              }
            }
          })
        : _vm._e(),
      _vm.oriModal
        ? _c("MeetingORIModal", {
            attrs: {
              item: _vm.oriModal.item,
              meeting: _vm.meeting,
              type: _vm.oriModal.type
            },
            on: {
              close: function() {
                return (_vm.oriModal = undefined)
              }
            }
          })
        : _vm._e(),
      _vm.accountModal
        ? _c("MeetingAccountModal", {
            attrs: { meeting: _vm.meeting },
            on: {
              close: function() {
                return (_vm.accountModal = false)
              }
            }
          })
        : _vm._e(),
      _vm.noteModal
        ? _c("MeetingNoteModal", {
            attrs: { note: _vm.noteModal },
            on: {
              close: function() {
                return (_vm.noteModal = undefined)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }