var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "meeting-outputs" }, [
    _c("div", { staticClass: "meeting-outputs-add" }, [
      _c(
        "div",
        {
          staticClass: "meeting-outputs-add-btn",
          class: { loading: _vm.loading },
          on: {
            click: function() {
              return _vm.$emit("add")
            }
          }
        },
        [
          _vm._v(" Add asset "),
          _c("img", {
            staticClass: "meeting-outputs-add-btn-icon",
            class: { loading: _vm.loading },
            attrs: {
              src: require("@/assets/icons/" +
                (_vm.loading ? "spinner" : "plus-medium") +
                ".svg"),
              alt: ""
            }
          })
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "meeting-outputs-list" },
      [
        _vm._l(_vm.outputBlocks, function(block, idx) {
          return _c(
            "div",
            {
              key: "output-blocks-" + idx,
              staticClass: "meeting-outputs-list-block"
            },
            [
              _c("p", { staticClass: "meeting-outputs-list-block-title" }, [
                _vm._v(_vm._s(block.title))
              ]),
              _vm._l(block.outputs, function(output) {
                return _c(
                  "div",
                  {
                    key: output.uuid,
                    staticClass: "meeting-outputs-item",
                    class: {
                      selected:
                        _vm.selected && _vm.selected.uuid === output.uuid,
                      disabled: _vm.loading.length
                    },
                    on: {
                      click: function() {
                        return _vm.$emit("select", output)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "meeting-outputs-item-icon",
                      attrs: {
                        src: require("@/assets/file_type/" +
                          (output.tool && output.tool.type === "presentation"
                            ? "powerpoint"
                            : "word") +
                          ".svg"),
                        alt: ""
                      }
                    }),
                    _c("p", { staticClass: "meeting-outputs-item-title" }, [
                      _vm._v(" " + _vm._s(output.name) + " ")
                    ]),
                    output.tool && !output.tool.dynamic
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: "Fixed - cannot be edited",
                              type: "is-dark"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "meeting-outputs-item-static",
                              attrs: {
                                src: require("@/assets/icons/lock-filled.svg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm.contentLoading.includes(output.uuid)
                      ? _c("img", {
                          staticClass: "meeting-outputs-item-loading",
                          attrs: {
                            src: require("@/assets/icons/spinner.svg"),
                            alt: ""
                          }
                        })
                      : _vm._e(),
                    _c(
                      "MeetingOutputActions",
                      _vm._g(
                        {
                          staticClass: "meeting-outputs-item-btn",
                          attrs: {
                            output: output,
                            "remove-loading": _vm.removeLoading.includes(
                              output.uuid
                            ),
                            disabled: _vm.contentLoading.includes(output.uuid)
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        }),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "meeting-outputs-item" },
              [
                _c("b-skeleton", {
                  attrs: { height: "1.5rem", width: "1.5rem" }
                }),
                _c("b-skeleton", {
                  attrs: { height: "1.2rem", width: "15rem" }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "meeting-outputs-item" },
              [
                _c("b-skeleton", {
                  attrs: { height: "1.5rem", width: "1.5rem" }
                }),
                _c("b-skeleton", {
                  attrs: { height: "1.2rem", width: "15rem" }
                })
              ],
              1
            )
          : _vm._e()
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "meeting-outputs-export" },
      [
        _c("Button", {
          attrs: {
            text: "Export",
            icon: "download",
            "icon-left": true,
            "full-width": true
          },
          on: {
            click: function() {
              return _vm.$emit("export")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }