<template>
  <div class="export-results">
    <div class="export-results-hero">
      <img
        src="@/assets/ori_loading.svg"
        alt=""
        class="export-results-hero-img"
      />
    </div>
    <div class="export-results-list">
      <div
        v-for="output in outputs"
        :key="`export-results-${output.uuid}`"
        class="export-results-list-item"
      >
        <img
          :src="
            require(`@/assets/file_type/${
              output.tool.type === 'presentation' ? 'powerpoint' : 'word'
            }.svg`)
          "
          alt=""
          class="export-results-list-item-icon"
        />
        <p class="export-results-list-item-name">{{ output.name }}</p>
        <StatusOrb
          :type="finished.includes(output.uuid) ? 'green' : 'orange'"
          :text="
            finished.includes(output.uuid)
              ? `${isDownload ? 'Download' : 'Upload'} complete`
              : `${isDownload ? 'Download' : 'Upload'}ing...`
          "
          :show-text="true"
        />
        <b-tooltip
          v-if="!isDownload"
          :label="`View on ${uploadProvider}`"
          type="is-dark"
          position="is-left"
          :append-to-body="false"
          class="export-results-list-item-link"
          :class="{ invisible: !finished.includes(output.uuid) }"
        >
          <Button
            icon="external-bold"
            type="light"
            :disabled="!finished.includes(output.uuid)"
            @click="() => gotoResult(output)"
          />
        </b-tooltip>
      </div>
    </div>
    <div class="export-results-updowns">
      <UploadDownload
        v-for="output in outputs"
        :ref="`export-updown-${output.uuid}`"
        :key="`export-updown-${output.uuid}`"
        :props-call="propsCalls[output.uuid]"
        :upload-callback="(res) => handleUploadFinish(output, res)"
        :download-callback="() => handleDownloadFinish(output)"
        :show-result="false"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import StatusOrb from '@c/library/StatusOrb.vue'
import UploadDownload from '@c/library/UploadDownload.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MeetingExportResults',
  components: { Button, StatusOrb, UploadDownload },
  props: {
    outputs: {
      type: Array,
      default: () => []
    },
    propsCalls: {
      type: Object,
      default: () => {}
    },
    exportLocation: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    finished: [],
    results: {}
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    isDownload() {
      return this.exportLocation === 'download'
    },
    uploadProvider() {
      const providers = {
        google_drive: 'Google Drive',
        sharepoint: 'Onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    }
  },
  mounted() {
    const func = `handle${this.isDownload ? 'Download' : 'Upload'}`
    this.outputs.forEach((output) => {
      this.$refs[`export-updown-${output.uuid}`]?.[0]?.[func]()
    })
  },
  methods: {
    gotoResult(output) {
      const url = this.results[output.uuid].url
      window.open(url, '_blank')
    },
    handleUploadFinish(output, res) {
      this.finished.push(output.uuid)
      this.results[output.uuid] = res
    },
    handleDownloadFinish(output) {
      this.finished.push(output.uuid)
      if (this.finished.length === this.outputs.length) this.$emit('finish')
    }
  }
}
</script>

<style lang="scss" scoped>
.export-results {
  &-hero {
    width: 100%;
    height: 10rem;
    background: #ece6ff;
    display: flex;
    justify-content: center;
    align-items: center;

    &-img {
      height: 65%;
    }
  }

  &-list {
    display: flex;
    flex-flow: column;
    padding: 1rem 1.5rem;
    max-height: 50vh;
    overflow: auto;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0.5rem;
      gap: 0.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.08);
      }

      &-icon {
        height: 1.2rem;
      }

      &-name {
        margin-right: auto;
      }

      &-link {
        &.invisible {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  &-updowns {
    visibility: hidden;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
