<template>
  <lottie-vue-player
    :src="imgUrl"
    :background-color="backgroundColor"
    :loop="loop"
    :autoplay="autoplay"
  />
</template>

<script>
export default {
  name: 'Animation',
  props: {
    name: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      default: '#000'
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgUrl() {
      return `${process.env.VUE_APP_WEBAPP_HOST}/animations/${this.name}.json`
    }
  }
}
</script>

<style lang="scss" scoped></style>
