var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "meeting-export" }, [
      _c(
        "div",
        { staticClass: "meeting-export-header" },
        [
          _c("p", { staticClass: "meeting-export-header-title" }, [
            _vm._v("Export generated content")
          ]),
          _c("Button", {
            attrs: { icon: "close", size: "xs", type: "grey" },
            on: { click: _vm.close }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "meeting-export-content",
          class: { padded: _vm.step < 2 }
        },
        [
          !_vm.step
            ? _c("div", { staticClass: "meeting-export-select" }, [
                _c("p", { staticClass: "meeting-export-select-header" }, [
                  _vm._v(" Select the content you want to export ")
                ]),
                _c(
                  "div",
                  { staticClass: "meeting-export-select-list" },
                  _vm._l(_vm.outputs, function(output) {
                    return _c(
                      "div",
                      {
                        key: "output-select-" + output.uuid,
                        staticClass: "meeting-export-select-list-item",
                        class: { disabled: _vm.loading.includes(output.uuid) },
                        on: {
                          click: function() {
                            return _vm.handleSelect(output)
                          }
                        }
                      },
                      [
                        _c("Checkbox", {
                          staticClass: "meeting-export-select-list-item-check",
                          attrs: { value: _vm.selected.includes(output.uuid) }
                        }),
                        _c("img", {
                          staticClass: "meeting-export-select-list-item-icon",
                          attrs: {
                            src: require("@/assets/file_type/" +
                              (output.tool.type === "presentation"
                                ? "powerpoint"
                                : "word") +
                              ".svg"),
                            alt: ""
                          }
                        }),
                        _vm._v(" " + _vm._s(output.name) + " "),
                        _vm.loading.includes(output.uuid)
                          ? _c("img", {
                              staticClass:
                                "meeting-export-select-list-item-loading",
                              attrs: {
                                src: require("@/assets/icons/spinner.svg"),
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            : _vm.step === 1
            ? _c(
                "div",
                { staticClass: "meeting-export-select" },
                [
                  _c("p", { staticClass: "meeting-export-select-header" }, [
                    _vm._v(" Choose where you would like to save your assets ")
                  ]),
                  _c("OutputLocationProp", {
                    attrs: { value: _vm.exportLocation },
                    on: { input: _vm.handleLocationSelect }
                  })
                ],
                1
              )
            : _c("MeetingExportResults", {
                attrs: {
                  outputs: _vm.selectedOutputs,
                  "props-calls": _vm.propsCalls,
                  "export-location": _vm.exportLocation
                }
              })
        ],
        1
      ),
      _vm.step < 2
        ? _c("div", { staticClass: "meeting-export-footer" }, [
            _c("div", [
              !_vm.step
                ? _c(
                    "div",
                    {
                      staticClass: "meeting-export-footer-selectall",
                      on: { click: _vm.selectAll }
                    },
                    [
                      _c("Checkbox", {
                        staticClass: "meeting-export-footer-selectall-check",
                        attrs: {
                          value: _vm.selected.length === _vm.outputs.length,
                          indeterminate:
                            _vm.selected.length &&
                            _vm.selected.length !== _vm.outputs.length
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selected.length === _vm.outputs.length
                              ? "Deselect all"
                              : "Select all"
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "meeting-export-footer-btns" },
              [
                _c("Button", {
                  attrs: { text: _vm.step ? "Back" : "Cancel", type: "white" },
                  on: { click: _vm.back }
                }),
                !_vm.step
                  ? _c("Button", {
                      attrs: { text: "Next", disabled: !_vm.selected.length },
                      on: {
                        click: function() {
                          return _vm.step++
                        }
                      }
                    })
                  : _c("Button", {
                      attrs: { text: "Export" },
                      on: { click: _vm.startExport }
                    })
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }