var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-ori" },
    [
      _c("p", { staticClass: "meeting-ori-subtitle" }, [
        _vm._v(_vm._s(_vm.subtitle))
      ]),
      _vm.type
        ? _c("MeetingORICustom", {
            ref: "meetingoricustom",
            attrs: { type: _vm.type, "refresh-inputs": _vm.refreshInputs },
            on: { submit: _vm.handleCustomSubmit }
          })
        : _vm._e(),
      _vm.selected && _vm.selected.length
        ? _c(
            "div",
            { staticClass: "meeting-ori-items" },
            _vm._l(_vm.selectedSorted, function(item) {
              return _c("MeetingContentCard", {
                key: item.explanation || item.uuid || item.name || item.title,
                staticClass: "meeting-ori-items-item",
                attrs: {
                  item: item,
                  selected: _vm.selected,
                  "enable-subofferings": true,
                  inspectable: true,
                  disabled: _vm.disabled
                },
                on: {
                  remove: _vm.handleRemove,
                  click: function(item) {
                    return _vm.$emit("detail", item)
                  }
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.type
        ? _c("MeetingORISuggestions", {
            attrs: {
              type: _vm.type,
              selected: _vm.selected,
              suggestions: _vm.suggestions,
              loading: _vm.suggestionsLoading,
              error: _vm.suggestionsError,
              disabled: _vm.disabled
            },
            on: {
              add: _vm.handleSubmit,
              remove: _vm.handleRemove,
              retry: _vm.handleSuggestionsError,
              detail: function(item) {
                return _vm.$emit("detail", item)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }