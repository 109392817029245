<template>
  <div class="document-preview">
    <div class="document-preview-text-container">
      <div class="document-preview-text-wrapper">
        <div class="document-preview-text">
          <HTMLRenderer v-if="!loading" :value="text" />
          <div v-else class="document-preview-text-loading">
            <b-skeleton height="1.5rem" width="90%" />
            <b-skeleton height="1.5rem" width="40%" />
            <b-skeleton height="1.5rem" width="70%" />
            <b-skeleton height="1.5rem" width="60%" />
            <b-skeleton height="1.5rem" width="50%" />
            <b-skeleton height="1.5rem" width="20%" />
          </div>
        </div>
        <div class="document-preview-text-padding"></div>
      </div>
    </div>
    <div
      v-if="output.tool && output.tool.dynamic"
      class="document-preview-prompt"
    >
      <TextInput
        v-model="prompt"
        placeholder='Optional instruction, try: "Make this sound easier" or "Put more emphasis on X"'
        :max-length="200"
        :disabled="loading"
        class="document-preview-prompt-input"
        @submit="() => generateOutput(true)"
      />
      <Button
        text="Regenerate"
        icon="ai"
        size="xs"
        :loading="loading"
        @click="() => generateOutput(true)"
      />
      <Button
        v-if="outputPrompt"
        text="Reset"
        size="xs"
        type="white"
        :loading="loading"
        @click="resetOutput"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import Button from '@c/library/Button.vue'
import HTMLRenderer from '@c/library/HTMLRenderer.vue'

export default {
  name: 'DocumentOutputPreview',
  components: {
    HTMLRenderer,
    TextInput,
    Button
  },
  props: {
    output: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    prompt: ''
  }),
  computed: {
    text() {
      return this.output?.document?.text || ''
    },
    outputPrompt() {
      return this.output?.document?.prompt || ''
    }
  },
  watch: {
    output: {
      handler() {
        this.prompt = this.output?.document?.prompt || ''
      },
      deep: true
    }
  },
  created() {
    this.prompt = this.output?.document?.prompt || ''
  },
  methods: {
    generateOutput() {
      this.$emit('regenerate', this.prompt)
    },
    resetOutput() {
      this.prompt = ''
      this.generateOutput()
    }
  }
}
</script>

<style lang="scss" scoped>
.document-preview {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 4.5rem 0;
  background: #f9f9fa;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f2f3;
    border-left: 1px solid rgba(#000, 0.08);
  }

  &::-webkit-scrollbar-thumb {
    background: #dddfe2;
    border-radius: 999px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken(#dddfe2, 10%);
  }

  &-text {
    min-height: 100%;
    background: white;
    padding: 7vh 10vh;
    border-radius: 2px;
    border: 1px solid rgba(#000, 0.08);
    position: relative;

    &-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      min-height: 100%;
    }

    &-container {
      width: 90%;
      padding-top: calc(90% * 1.414);
      position: relative;
    }

    &-padding {
      height: calc(5vh + 5rem);
      min-height: calc(5vh + 5rem);
    }

    &-regen {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  &-prompt {
    width: calc(100% - 2rem - 10px);
    position: absolute;
    z-index: 10;
    bottom: 5%;
    left: 1rem;
    right: calc(1rem + 10px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(#000, 0.08);

    &-input {
      flex: 1;
    }
  }
}
</style>
