<template>
  <div class="save-location">
    <div class="save-location-list">
      <div
        v-for="location in outputLocations"
        :key="location.key"
        class="save-location-list-item"
        :class="{
          selected: value === location.key,
          disabled: location.isUpload && !location.connected
        }"
        @click="() => handleSelect(location)"
      >
        <Checkbox
          :value="value === location.key"
          type="radio"
          class="save-location-list-item-radio"
        />
        <div class="save-location-list-item-info">
          <p class="save-location-list-item-title">{{ location.text }}</p>
          <p class="save-location-list-item-description">
            {{ location.description }}
          </p>
        </div>
        <img :src="location.icon" alt="" class="save-location-list-item-img" />
        <UploadDownload
          v-show="location.isUpload && !location.connected"
          class="save-location-list-item-connect"
          @connected="() => handleSelect(location)"
        >
          <template #default="{ connect }">
            <Button text="Connect" @click="() => connect(true)" />
          </template>
        </UploadDownload>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { userFileStorageIntegrations } from '@c/integrations'
import Checkbox from '@c/library/Checkbox.vue'
import Button from '@c/library/Button.vue'
import UploadDownload from '@c/library/UploadDownload.vue'

export default {
  name: 'OutputLocationProp',
  components: { Checkbox, Button, UploadDownload },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'currentUser', 'userIntegrations']),
    uploadProvider() {
      const providers = {
        google_drive: 'google_drive',
        sharepoint: 'onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    },
    outputLocations() {
      const uploadInfo = this.uploadProvider
        ? userFileStorageIntegrations[this.uploadProvider]
        : null
      return [
        ...(uploadInfo
          ? [
              {
                key: uploadInfo.key,
                text: `Save to ${uploadInfo.title}`,
                description: `Upload the results to your personal ${uploadInfo.title} so you can edit them online`,
                icon: uploadInfo.image,
                isUpload: true,
                connected: !!this.userIntegrations.find(
                  (ui) => ui.type === this.uploadProvider
                )
              }
            ]
          : []),
        {
          key: 'download',
          text: 'Download to local computer',
          description:
            'Save the files to your local computer, you can edit them offline',
          icon: require('@/assets/icons/download.svg')
        }
      ]
    }
  },
  created() {
    if (!this.value) this.selectFirstValid()
  },
  methods: {
    selectFirstValid() {
      const valid = this.outputLocations.find((loc) =>
        loc.isUpload ? loc.connected : true
      )
      if (valid) this.handleSelect(valid)
    },
    handleSelect(location) {
      if (location.isUpload && !location.connected) return
      this.$emit('input', location.key)
    }
  }
}
</script>

<style lang="scss" scoped>
.save-location {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;

  &-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.75rem;
      align-items: center;
      padding: 0.75rem;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.08);
      user-select: none;
      cursor: pointer;

      &:not(.disabled):hover {
        background: rgba(#000, 0.02);
      }

      &.disabled {
        cursor: not-allowed;

        & > *:not(.save-location-list-item-connect) {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      &.selected {
        border: 1px solid $primary;
        background: rgba($primary, 0.02);

        &:hover {
          background: rgba($primary, 0.04);
        }
      }

      &-info {
        flex: 1;
      }

      &-radio {
        pointer-events: none;
      }

      &-title {
        font-weight: 600;
      }

      &-description {
        color: #60666b;
      }

      &-img {
        width: 1.75rem;
        min-width: 1.75rem;
      }
    }
  }
}
</style>
