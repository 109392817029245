<template>
  <b-modal
    :active="showModal"
    :can-cancel="['escape', 'outside']"
    @close="close"
  >
    <div v-if="!step" class="meeting-edit">
      <div class="meeting-edit-header">
        <p class="meeting-edit-header-text">Edit meeting {{ typeName }}</p>
        <Button icon="close" type="grey" size="xs" @click="close" />
      </div>
      <div class="meeting-edit-content" @scroll="handleContentScroll">
        <component
          :is="editComponent"
          ref="meetingeditcomponent"
          :key="`meetingedit-${editComponentKey}`"
          v-bind="editProps"
          @input="(item) => editFunction(item)"
          @remove="
            (item, is_account) => editFunction(item, true, false, is_account)
          "
          @edit="(item) => editFunction(item, false, true)"
          @detail="(item) => $emit('detail', item)"
          @suggestions="(s) => $emit('suggestions', s, type)"
        />
        <b-loading :active="loading" :is-full-page="false" />
      </div>
    </div>
    <div v-else class="meeting-edit">
      <div class="meeting-edit-header">
        <p class="meeting-edit-header-text">Regenerate content</p>
        <Button icon="close" type="grey" size="xs" @click="close" />
      </div>
      <div class="meeting-edit-content">
        <p class="meeting-edit-content-text">
          You edited this meeting's {{ typeName }}. Would you like to regenerate
          the selected content with this new information?
        </p>
        <div class="meeting-edit-content-list">
          <div
            v-for="output in dynamicOutputs"
            :key="`output-${output.uuid}`"
            class="meeting-edit-content-list-item"
            :class="{ disabled: contentLoading.includes(output.uuid) }"
            @click="() => selectRegenerate(output)"
          >
            <Checkbox
              :value="regenerate.some((o) => o.uuid === output.uuid)"
              class="meeting-edit-content-list-item-check"
            />
            <img
              :src="
                require(`@/assets/file_type/${
                  output.tool.type === 'presentation' ? 'powerpoint' : 'word'
                }.svg`)
              "
              alt=""
              class="meeting-edit-content-list-item-icon"
            />
            <p class="meeting-edit-content-list-item-text">
              {{ output.name }}
            </p>
            <img
              v-if="contentLoading.includes(output.uuid)"
              src="@/assets/icons/spinner.svg"
              alt=""
              class="meeting-edit-content-list-item-loading"
            />
          </div>
        </div>
      </div>
      <div class="meeting-edit-footer">
        <Button text="Cancel" type="white" @click="close" />
        <Button text="Regenerate" @click="handleRegenerate" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import MeetingAudience from './MeetingAudience.vue'
import MeetingNotes from './MeetingNotes.vue'
import MeetingORI from './MeetingORI.vue'
import { editMeeting } from '@/services/meetingService'
import Checkbox from '@c/library/Checkbox.vue'

export default {
  name: 'MeetingEditModal',
  components: { Button, Checkbox },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    meeting: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'people',
      validator: (val) =>
        [
          '',
          'people',
          'notes',
          'offerings',
          'references',
          'inspirations'
        ].includes(val)
    },
    outputs: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: () => ({})
    },
    contentLoading: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    showModal: false,
    edited: false,
    step: 0,
    loading: false,
    regenerate: [],
    editComponentKey: 0
  }),
  computed: {
    typeName() {
      return (
        {
          people: 'audience',
          notes: 'notes',
          offerings: 'offerings',
          references: 'cases',
          inspirations: 'inspirational content'
        }[this.type] || 'meeting'
      )
    },
    editComponent() {
      return (
        {
          people: MeetingAudience,
          notes: MeetingNotes
        }[this.type] || MeetingORI
      )
    },
    editProps() {
      return (
        {
          people: {
            selection: this.meeting.people || [],
            account: this.meeting.account || {}
          },
          notes: {
            notes: this.meeting.notes || [],
            account: this.meeting.account || {}
          }
        }[this.type] || {
          type: this.type,
          selected: this.meeting[this.type] || [],
          meeting: this.meeting
        }
      )
    },
    editFunction() {
      return this.type === 'notes'
        ? this.handleEditNotes
        : this.handleEditMeeting
    },
    dynamicOutputs() {
      return this.outputs.filter((o) => o.tool && o.tool.dynamic)
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.initEdit()
      }
    },
    meeting: {
      handler() {
        if (this.type === 'notes') this.editComponentKey++
      },
      deep: true
    },
    contentLoading(newVal, oldVal) {
      if (
        this.step &&
        this.selected &&
        (newVal || []).includes(this.selected.uuid) &&
        !(oldVal || []).includes(this.selected.uuid)
      ) {
        this.regenerate.push(this.selected)
      }
    }
  },
  methods: {
    initEdit() {
      if (!this.visible) return
      this.showModal = true
      this.edited = false
    },
    close() {
      if (
        this.edited &&
        !this.step &&
        !['people', 'notes', 'offerings'].includes(this.type) &&
        this.dynamicOutputs.length
      ) {
        this.step++
        this.regenerate =
        this.selected?.tool?.dynamic && !this.contentLoading.includes(this.selected.uuid)
            ? [this.selected]
            : []
        return
      }
      this.showModal = false
      this.step = 0
      this.regenerate = []
      this.$emit('close')
    },
    emitEdit(meeting) {
      this.$emit('input', meeting)
      this.edited = true
    },
    handleRegenerate() {
      this.$emit('regenerate', this.regenerate)
      this.close()
    },
    async handleEditNotes(
      note,
      remove = false,
      edit = false,
      isAccountNote = false
    ) {
      if (edit) {
        this.emitEdit({
          ...this.meeting,
          account: {
            ...this.meeting.account,
            notes: this.meeting.account.notes.map((n) =>
              n.uuid === note.uuid ? note : n
            )
          },
          notes: this.meeting.notes.map((n) =>
            n.uuid === note.uuid ? note : n
          )
        })
        return
      }
      try {
        this.loading = true
        let meeting = { ...this.meeting }
        if (remove) {
          const notesIdx = this.meeting.notes.findIndex(
            (n) => n.uuid === note.uuid
          )
          if (notesIdx > -1) {
            meeting = await editMeeting({
              workspace_id: this.$route.params.workspace_id,
              story_id: this.meeting.uuid,
              note_ids: this.meeting.notes
                .filter((n) => n.uuid !== note.uuid)
                .map((n) => n.uuid)
            })
          }
          if (isAccountNote) {
            const accountNotesIdx = this.meeting.account.notes.findIndex(
              (n) => n.uuid === note.uuid
            )
            if (accountNotesIdx > -1) {
              meeting = {
                ...meeting,
                account: {
                  ...this.meeting.account,
                  notes: this.meeting.account.notes.filter(
                    (n) => n.uuid !== note.uuid
                  )
                }
              }
            }
          }
        } else {
          meeting = await editMeeting({
            workspace_id: this.$route.params.workspace_id,
            story_id: this.meeting.uuid,
            note_ids: [
              ...(this.meeting.notes || []).map((n) => n.uuid),
              note.uuid
            ]
          })
          if (
            !(this.meeting.account.notes || []).some(
              (n) => n.uuid === note.uuid
            )
          ) {
            meeting = {
              ...meeting,
              account: {
                ...this.meeting.account,
                notes: [...(this.meeting.account.notes || []), note]
              }
            }
          }
        }
        this.emitEdit(meeting)
      } catch (e) {
        this.$console.debug('Error editing notes', e)
        this.$toast.error(e, 'editing meeting notes')
      } finally {
        this.loading = false
      }
    },
    async handleEditMeeting(value, remove = false) {
      try {
        this.loading = true
        let values = this.meeting[this.type] || []
        const id = (val) => val.uuid || val.linkedin_url || val.role
        if (remove) {
          values = values.filter((item) => id(item) !== id(value))
        } else {
          values = [...values, value]
        }

        const meeting = await editMeeting({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          ...(this.type === 'people'
            ? {
                people: values
              }
            : {
                [`${this.type.slice(0, -1)}_ids`]: values.map((val) => val.uuid)
              })
        })
        this.emitEdit(meeting)
      } catch (e) {
        this.$console.debug('Error editing meeting', e)
        this.$toast.error(e, 'editing meeting')
      } finally {
        this.loading = false
      }
    },
    selectRegenerate(template) {
      this.regenerate = this.regenerate.some((o) => o.uuid === template.uuid)
        ? this.regenerate.filter((o) => o.uuid !== template.uuid)
        : [...this.regenerate, template]
    },
    handleContentScroll() {
      if (this.type && this.type !== 'notes')
        this.$refs.meetingeditcomponent.blur()
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-edit {
  border-radius: 8px;
  background: white;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-text {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &-content {
    position: relative;
    min-height: 10rem;
    max-height: 70vh;
    overflow-y: auto;
    padding: 1.5rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-text {
      color: #60666b;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      margin: 1rem 10rem;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      background: #f1f2f3;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        padding: 0.75rem 1.5rem;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(#000, 0.08);
        }

        &:hover {
          background: rgba(#000, 0.04);
        }

        &-check {
          pointer-events: none;
        }

        &-icon {
          height: 1.2rem;
        }

        &-loading {
          height: 1.2rem;
          animation: spin 1s linear infinite;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 1.5rem;
    gap: 1rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
