var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.visible,
        "can-cancel": _vm.loading ? [] : ["escape", "outside"],
        "destroy-on-hide": false
      },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "meeting-changed" }, [
        _c(
          "div",
          { staticClass: "meeting-changed-header" },
          [
            _c("p", { staticClass: "meeting-changed-header-title" }, [
              _vm._v("Meeting updated")
            ]),
            _c("Button", {
              attrs: { icon: "close", type: "grey", size: "xs" },
              on: { click: _vm.close }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "meeting-changed-content" },
          [
            _c("div", { staticClass: "meeting-changed-content-header" }, [
              _c("img", {
                staticClass: "meeting-changed-content-header-icon",
                attrs: { src: require("@/assets/icons/ai.svg"), alt: "" }
              }),
              _vm._v(" " + _vm._s(_vm.contentTitle) + " ")
            ]),
            _vm.modalKey
              ? _c("NewMeetingSuggestions", {
                  key: _vm.modalKey,
                  staticClass: "meeting-changed-content-suggestions",
                  class: { visible: !_vm.step },
                  attrs: {
                    meeting: _vm.localMeeting,
                    "hide-tabs": _vm.hideTabs,
                    "max-height": "60vh"
                  },
                  on: {
                    loading: function(l) {
                      return (_vm.loading = l)
                    },
                    edit: _vm.handleEdit,
                    suggestions: function(s, t) {
                      return _vm.$emit("suggestions", s, t)
                    },
                    detail: function(item, type) {
                      return _vm.$emit("detail", item, type)
                    }
                  }
                })
              : _vm._e(),
            _vm.step
              ? _c(
                  "div",
                  { staticClass: "meeting-changed-content-regen" },
                  _vm._l(_vm.dynamicOutputs, function(output) {
                    return _c(
                      "div",
                      {
                        key: "output-select-" + output.uuid,
                        staticClass: "meeting-changed-content-regen-item",
                        on: {
                          click: function() {
                            return _vm.handleSelect(output)
                          }
                        }
                      },
                      [
                        _c("Checkbox", {
                          staticClass:
                            "meeting-changed-content-regen-item-check",
                          attrs: { value: _vm.selected.includes(output.uuid) }
                        }),
                        _c("img", {
                          staticClass:
                            "meeting-changed-content-regen-item-icon",
                          attrs: {
                            src: require("@/assets/file_type/" +
                              (output.tool.type === "presentation"
                                ? "powerpoint"
                                : "word") +
                              ".svg"),
                            alt: ""
                          }
                        }),
                        _vm._v(" " + _vm._s(output.name) + " ")
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "meeting-changed-footer" },
          [
            _c("Button", {
              attrs: {
                text: _vm.step ? "Finish" : "Next",
                loading: _vm.loading,
                icon: "chevron-right-medium"
              },
              on: { click: _vm.next }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }