<template>
  <b-modal :active="visible" @close="close">
    <div class="name-output">
      <div class="name-output-header">
        <p class="name-output-header-title">Rename "{{ outputName }}"</p>
        <Button icon="close" type="grey" @click="close" />
      </div>
      <div class="name-output-content">
        <TextInput
          v-model="name"
          placeholder="Edit output name"
          @submit="rename"
        />
      </div>
      <div class="name-output-footer">
        <Button text="Cancel" type="white" :disabled="loading" @click="close" />
        <Button
          text="Rename"
          :disabled="name === outputName"
          :loading="loading"
          @click="rename"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import { editMeetingOutputName } from '@/services/meetingService'

export default {
  name: 'MeetingOutputNameModal',
  components: {
    Button,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    output: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    name: '',
    loading: false
  }),
  computed: {
    outputName() {
      return this.output?.name || ''
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.name = this.output.name
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async rename() {
      try {
        this.loading = true
        const res = await editMeetingOutputName({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.$route.params.meeting_id,
          output_id: this.output.uuid,
          name: this.name
        })
        this.$emit('rename', res)
      } catch (error) {
        this.$console.debug('Error renaming meeting output', error)
        this.$toast.error(error, 'renaming meeting output')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.name-output {
  background: white;
  border-radius: 8px;
  width: min(30rem, 90vw);

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  &-content {
    padding: 1rem 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem 1.5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
